/* colors */
/* urls */
/* fonts */
/* grids */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 17, 2017 */
@font-face {
  font-family: 'nunito_sansblack';
  src: url("../fonts/nunitosans-black-webfont.eot");
  src: url("../fonts/nunitosans-black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-black-webfont.woff2") format("woff2"), url("../fonts/nunitosans-black-webfont.woff") format("woff"), url("../fonts/nunitosans-black-webfont.ttf") format("truetype"), url("../fonts/nunitosans-black-webfont.svg#nunito_sansblack") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sansblack_italic';
  src: url("../fonts/nunitosans-blackitalic-webfont.eot");
  src: url("../fonts/nunitosans-blackitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-blackitalic-webfont.woff2") format("woff2"), url("../fonts/nunitosans-blackitalic-webfont.woff") format("woff"), url("../fonts/nunitosans-blackitalic-webfont.ttf") format("truetype"), url("../fonts/nunitosans-blackitalic-webfont.svg#nunito_sansblack_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sansbold';
  src: url("../fonts/nunitosans-bold-webfont.eot");
  src: url("../fonts/nunitosans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-bold-webfont.woff2") format("woff2"), url("../fonts/nunitosans-bold-webfont.woff") format("woff"), url("../fonts/nunitosans-bold-webfont.ttf") format("truetype"), url("../fonts/nunitosans-bold-webfont.svg#nunito_sansbold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sansbold_italic';
  src: url("../fonts/nunitosans-bolditalic-webfont.eot");
  src: url("../fonts/nunitosans-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-bolditalic-webfont.woff2") format("woff2"), url("../fonts/nunitosans-bolditalic-webfont.woff") format("woff"), url("../fonts/nunitosans-bolditalic-webfont.ttf") format("truetype"), url("../fonts/nunitosans-bolditalic-webfont.svg#nunito_sansbold_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sansextrabold';
  src: url("../fonts/nunitosans-extrabold-webfont.eot");
  src: url("../fonts/nunitosans-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-extrabold-webfont.woff2") format("woff2"), url("../fonts/nunitosans-extrabold-webfont.woff") format("woff"), url("../fonts/nunitosans-extrabold-webfont.ttf") format("truetype"), url("../fonts/nunitosans-extrabold-webfont.svg#nunito_sansextrabold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sansextrabold_italic';
  src: url("../fonts/nunitosans-extrabolditalic-webfont.eot");
  src: url("../fonts/nunitosans-extrabolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-extrabolditalic-webfont.woff2") format("woff2"), url("../fonts/nunitosans-extrabolditalic-webfont.woff") format("woff"), url("../fonts/nunitosans-extrabolditalic-webfont.ttf") format("truetype"), url("../fonts/nunitosans-extrabolditalic-webfont.svg#nunito_sansextrabold_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sansextralight';
  src: url("../fonts/nunitosans-extralight-webfont.eot");
  src: url("../fonts/nunitosans-extralight-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-extralight-webfont.woff2") format("woff2"), url("../fonts/nunitosans-extralight-webfont.woff") format("woff"), url("../fonts/nunitosans-extralight-webfont.ttf") format("truetype"), url("../fonts/nunitosans-extralight-webfont.svg#nunito_sansextralight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sansextralight_italic';
  src: url("../fonts/nunitosans-extralightitalic-webfont.eot");
  src: url("../fonts/nunitosans-extralightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/nunitosans-extralightitalic-webfont.woff") format("woff"), url("../fonts/nunitosans-extralightitalic-webfont.ttf") format("truetype"), url("../fonts/nunitosans-extralightitalic-webfont.svg#nunito_sansextralight_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sansitalic';
  src: url("../fonts/nunitosans-italic-webfont.eot");
  src: url("../fonts/nunitosans-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-italic-webfont.woff2") format("woff2"), url("../fonts/nunitosans-italic-webfont.woff") format("woff"), url("../fonts/nunitosans-italic-webfont.ttf") format("truetype"), url("../fonts/nunitosans-italic-webfont.svg#nunito_sansitalic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sanslight';
  src: url("../fonts/nunitosans-light-webfont.eot");
  src: url("../fonts/nunitosans-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-light-webfont.woff2") format("woff2"), url("../fonts/nunitosans-light-webfont.woff") format("woff"), url("../fonts/nunitosans-light-webfont.ttf") format("truetype"), url("../fonts/nunitosans-light-webfont.svg#nunito_sanslight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sanslight_italic';
  src: url("../fonts/nunitosans-lightitalic-webfont.eot");
  src: url("../fonts/nunitosans-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-lightitalic-webfont.woff2") format("woff2"), url("../fonts/nunitosans-lightitalic-webfont.woff") format("woff"), url("../fonts/nunitosans-lightitalic-webfont.ttf") format("truetype"), url("../fonts/nunitosans-lightitalic-webfont.svg#nunito_sanslight_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sansregular';
  src: url("../fonts/nunitosans-regular-webfont.eot");
  src: url("../fonts/nunitosans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-regular-webfont.woff2") format("woff2"), url("../fonts/nunitosans-regular-webfont.woff") format("woff"), url("../fonts/nunitosans-regular-webfont.ttf") format("truetype"), url("../fonts/nunitosans-regular-webfont.svg#nunito_sansregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sanssemibold';
  src: url("../fonts/nunitosans-semibold-webfont.eot");
  src: url("../fonts/nunitosans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-semibold-webfont.woff2") format("woff2"), url("../fonts/nunitosans-semibold-webfont.woff") format("woff"), url("../fonts/nunitosans-semibold-webfont.ttf") format("truetype"), url("../fonts/nunitosans-semibold-webfont.svg#nunito_sanssemibold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'nunito_sanssemibold_italic';
  src: url("../fonts/nunitosans-semibolditalic-webfont.eot");
  src: url("../fonts/nunitosans-semibolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nunitosans-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/nunitosans-semibolditalic-webfont.woff") format("woff"), url("../fonts/nunitosans-semibolditalic-webfont.ttf") format("truetype"), url("../fonts/nunitosans-semibolditalic-webfont.svg#nunito_sanssemibold_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  background: #F7F6F7;
  font-size: 15px;
  line-height: 20px;
  color: #43165C;
  overflow-x: hidden;
  font-family: "nunito_sanslight"; }

.site-wrapper {
  padding-top: 70px;
  /*@media(max-width: $screen-xs-max) {
    padding-top: 50px;
  }*/ }

.container {
  padding: 0 30px;
  width: 100%;
  max-width: 1650px;
  margin: 0 auto; }
  @media (max-width: 480px) {
    .container {
      padding-left: 15px;
      padding-right: 15px; } }

.container--checkout {
  max-width: 1050px;
  padding: 0 15px; }

.row--30 {
  margin-left: -30px;
  margin-right: -30px; }
  @media (max-width: 480px) {
    .row--30 {
      margin-left: -15px;
      margin-right: -15px; } }

.section--main {
  padding: 130px 0 115px 0; }
  @media (max-width: 1199px) {
    .section--main {
      padding: 80px 0 60px 0; } }
  @media (max-width: 767px) {
    .section--main {
      padding: 60px 0 60px 0; } }
  @media (min-width: 1200px) {
    .section--main.pt--90 {
      padding-top: 90px; } }
  .section--main.pt--75 {
    padding-top: 75px; }

.main--over-hero {
  margin-top: -200px; }
  @media (max-width: 767px) {
    .main--over-hero {
      margin-top: -100px; } }

.main--over-hero-100 {
  margin-top: -100px; }

.main--over-hero-250 {
  margin-top: -250px; }
  @media (max-width: 767px) {
    .main--over-hero-250 {
      margin-top: -150px; } }

span, .inline, .breadcrumb .breadcrumb__item + li:before {
  display: inline-block;
  vertical-align: middle; }

.bg--white {
  background-color: white; }

.main--white-to-white {
  padding-top: 20px; }

.bg--lightgray {
  background-color: #F7F6F7 !important; }

.h--100 {
  height: 100%; }

.w--100 {
  width: 100%; }

.pr--15 {
  padding-right: 15px; }

.pl--15 {
  padding-left: 15px; }

.pt--50 {
  padding-top: 50px; }

img {
  max-width: 100%; }

.no-padding-top {
  padding-top: 0; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

hr {
  border-top-color: #d5d0d7; }
  hr.purple {
    border-top-color: #43165C; }
  hr.small {
    margin-top: 7px;
    margin-bottom: 7px; }
  hr.big {
    margin-top: 40px;
    margin-bottom: 40px; }

.mw--1050 {
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto; }

.mw--1200 {
  max-width: 1200px; }

.mt--15 {
  margin-top: 15px !important; }

.mt--30 {
  margin-top: 30px !important; }

.mt--60 {
  margin-top: 60px !important; }

.mt--90 {
  margin-top: 90px !important; }

.col-main {
  padding-left: 60px;
  border-left: 1px solid #d5d0d7; }
  @media (max-width: 1199px) {
    .col-main {
      padding-left: 30px; } }
  @media (max-width: 767px) {
    .col-main {
      padding-left: 20px;
      border-left: none; } }
  @media (max-width: 480px) {
    .col-main {
      padding-left: 15px; } }

.mr--5 {
  margin-right: 5px !important; }

.mr--10 {
  margin-right: 10px !important; }

.mr--20 {
  margin-right: 20px !important; }

.relative {
  position: relative; }

.gradientH, #header-search, .header__collapse-content, .box--top-gradient:before {
  background-color: #9c255f;
  background-image: -ms-linear-gradient(right, #43165C 60%, #f43462 100%);
  background-image: -moz-linear-gradient(right, #43165C 60%, #f43462 100%);
  background-image: -o-linear-gradient(right, #43165C 60%, #f43462 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #43165C), color-stop(1%, #f43462));
  background-image: -webkit-linear-gradient(left, #43165C 60%, #f43462 100%);
  background-image: linear-gradient(to right, #43165C 60%, #f43462 100%); }

.gradientV, .box--left-gradient:before {
  background-color: #9c255f;
  background-image: -ms-linear-gradient(top, #43165C 60%, #f43462 100%);
  background-image: -moz-linear-gradient(top, #43165C 60%, #f43462 100%);
  background-image: -o-linear-gradient(top, #43165C 60%, #f43462 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #43165C), color-stop(1%, #f43462));
  background-image: -webkit-linear-gradient(top, #43165C 60%, #f43462 100%);
  background-image: linear-gradient(to bottom, #43165C 60%, #f43462 100%); }

.gradientD, .site-footer {
  background-color: #711e5e;
  background-image: -ms-linear-gradient(45deg, #43165C 50%, #9e255f 110%);
  background-image: -moz-linear-gradient(45deg, #43165C 50%, #9e255f 110%);
  background-image: -o-linear-gradient(45deg, #43165C 50%, #9e255f 110%);
  background-image: -webkit-linear-gradient(45deg, #43165C 50%, #9e255f 110%);
  background-image: linear-gradient(45deg, #43165C 50%, #9e255f 110%); }

h1, h2, h3, h4 {
  font-family: "nunito_sansblack"; }
  h1.text--bold, h2.text--bold, h3.text--bold, h4.text--bold {
    font-family: "nunito_sansbold"; }
  h1.mb--10, h2.mb--10, h3.mb--10, h4.mb--10 {
    margin-bottom: 10px; }
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover {
    text-decoration: none; }

h1 {
  font-size: 45px;
  line-height: 45px;
  margin: 0 0 35px 0; }
  @media (max-width: 767px) {
    h1 {
      font-size: 40px;
      line-height: 40px; } }
  @media (max-width: 480px) {
    h1 {
      font-size: 35px;
      line-height: 35px; } }

.heading-1--small {
  font-size: 30px;
  line-height: 30px; }

.section--main > .container > h1, .section--main > .container > h3 {
  margin-bottom: 60px; }

section > .container > h1, section > .container > h2, section > .container > h3 {
  text-align: center; }
  section > .container > h1:after, section > .container > h2:after, section > .container > h3:after {
    margin-left: auto;
    margin-right: auto; }

h2 {
  font-size: 30px;
  line-height: 30px;
  margin: 0 0 25px 0; }
  @media (max-width: 991px) {
    h2 {
      font-size: 25px;
      line-height: 28px;
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    h2 {
      font-size: 22px;
      line-height: 24px; } }

.heading-2--big {
  font-size: 36px;
  line-height: 36px; }
  @media (max-width: 1199px) {
    .heading-2--big {
      font-size: 30px;
      line-height: 30px; } }
  @media (max-width: 1199px) {
    .heading-2--big {
      font-size: 25px;
      line-height: 28px; } }

h3 {
  font-size: 22px;
  line-height: 22px;
  margin: 0 0 5px 0; }
  h3.mb--70 {
    margin-bottom: 70px; }

.heading-3--big {
  font-size: 25px;
  line-height: 28px; }

.heading-3--small {
  font-size: 18px;
  line-height: 22px; }

h4 {
  font-size: 20px;
  line-height: 22px;
  margin: 0 0 15px 0; }

.heading-line, .contact-info {
  margin-bottom: 0; }
  .heading-line h1, .contact-info h1 {
    margin-bottom: 10px; }
  .heading-line h2, .contact-info h2, .heading-line h3, .contact-info h3 {
    margin-bottom: 0; }
  .heading-line:after, .contact-info:after {
    content: "";
    display: block;
    width: 60px;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 25px;
    background-color: #43165C; }
  .heading-line.heading-line--big:after, .heading-line--big.contact-info:after {
    margin-top: 30px;
    margin-bottom: 30px; }
  .heading-line.text--white:after, .text--white.contact-info:after {
    background-color: white; }
  .heading-line.text-center:after, .text-center.contact-info:after {
    margin-left: auto;
    margin-right: auto; }

.box .heading-line:after, .box .contact-info:after {
  margin-top: 15px;
  margin-bottom: 18px; }

.box .heading-line.heading-line--big:after, .box .heading-line--big.contact-info:after {
  margin-top: 30px;
  margin-bottom: 30px; }

.box .heading-line.heading-2--big:after, .box .heading-2--big.contact-info:after {
  margin-top: 20px;
  margin-bottom: 25px; }

.box .heading-line--big:after {
  margin-top: 20px;
  margin-bottom: 25px; }

p {
  font-size: 15px;
  line-height: 20px;
  margin: 0 0 15px 0; }

b, strong, .text--bold {
  font-family: "nunito_sansbold";
  display: inline-block;
  vertical-align: middle; }

.text--white {
  color: white; }

.text--blue {
  color: #009FE3; }

.text--purple {
  color: #43165C; }

.text--red {
  color: #FF3662; }

.text--10 {
  font-size: 10px;
  line-height: 14px; }

.text--12 {
  font-size: 12px;
  line-height: 16px; }

.text--14 {
  font-size: 14px;
  line-height: 18px; }

.text--20 {
  font-size: 20px;
  line-height: 24px; }

.text--22 {
  font-size: 22px;
  line-height: 26px; }

.text--30 {
  font-size: 30px;
  line-height: 34px; }

.text--50 {
  font-size: 50px;
  line-height: 60px; }

.text--black {
  font-family: "nunito_sansblack"; }

.text--black-italic {
  font-family: "nunito_sansblack_italic"; }

.text--extrabold {
  font-family: "nunito_sansextrabold"; }

.text--regular {
  font-family: "nunito_sansregular"; }

.text--light-italic {
  font-family: "nunito_sanslight_italic"; }

.text--light {
  font-family: "nunito_sanslight"; }

ul,
ol {
  margin: 0 0 15px 0;
  padding-left: 11px; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

ul {
  list-style: none;
  font-size: 14px;
  line-height: 20px; }
  ul li span, ul li a {
    display: inline-block;
    vertical-align: top; }
  ul li a {
    color: #43165C; }
  ul li > ul {
    padding-left: 20px; }
  ul.list-unstyled > li {
    padding-left: 0; }

ul {
  padding-left: 0;
  /*&.text--black {
    > li {
      &:after {
        font-size: 12px;
      }
    }
  }*/ }
  ul > li {
    position: relative;
    padding-left: 20px; }
    ul > li:after {
      content: "\2022";
      position: absolute;
      left: 0;
      top: 0;
      font-size: 18px;
      display: inline-block;
      vertical-align: top;
      line-height: 20px; }

.text--left {
  text-align: left !important; }

.text--right {
  text-align: right !important; }

.cursor-pointer {
  cursor: pointer; }

.disable-select, .radio-wrapper, .checkbox-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/*.word-wrap {
  @include word-wrap();
}*/
[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  vertical-align: middle; }

.icon--left {
  margin-right: 5px; }

.icon--right, .address-list .address-list__item .icon-delete {
  margin-left: 5px; }

.icon-logout {
  margin-top: -3px; }

.iconmoon, .custom-checkbox:before, .jcf-select .jcf-select-opener:before, .breadcrumb .breadcrumb__item + li:before, .header-right-content__item[data-toggle="collapse"][aria-expanded="true"][href="#header-search"] i:before, .filter-dropdown__btn:before, .aside-nav__item a:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icons/fonts/icomoon.eot?7b6q2t");
  src: url("../fonts/icons/fonts/icomoon.eot?7b6q2t#iefix") format("embedded-opentype"), url("../fonts/icons/fonts/icomoon.ttf?7b6q2t") format("truetype"), url("../fonts/icons/fonts/icomoon.woff?7b6q2t") format("woff"), url("../fonts/icons/fonts/icomoon.svg?7b6q2t#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-delete:before {
  content: "\e902"; }

.icon-arrowleft:before {
  content: "\e903"; }

.icon-arrowright:before, .breadcrumb .breadcrumb__item + li:before, .aside-nav__item a:before {
  content: "\e904"; }

.icon-arrowup:before {
  content: "\e905"; }

.icon-arrowdown:before, .jcf-select .jcf-select-opener:before, .filter-dropdown__btn:before {
  content: "\e906"; }

.icon-cart:before {
  content: "\e907"; }

.icon-close:before, .jcf-select.jcf-drop-active .jcf-select-opener:before, .link--toggle[aria-expanded="true"] i:before, .header-right-content__item[data-toggle="collapse"][aria-expanded="true"][href="#header-search"] i:before, .filter-dropdown__btn[aria-expanded="true"]:before {
  content: "\e908"; }

.icon-search:before {
  content: "\e909"; }

.icon-time:before {
  content: "\e90a"; }

.icon-edit:before {
  content: "\e90b"; }

.icon-play:before {
  content: "\e900"; }

.icon-file:before {
  content: "\e901"; }

.icon-download:before {
  content: "\e90c"; }

.icon-play2:before {
  content: "\e90d"; }

.icon-profile:before {
  content: "\e90e"; }

.icon-plus:before {
  content: "\e90f"; }

.icon-check:before, .custom-checkbox:before {
  content: "\e910"; }

.icon-logout:before {
  content: "\e911"; }

.icon-eye:before {
  content: "\e912"; }

.icon-phone:before {
  content: "\e913"; }

.icon-mail:before {
  content: "\e914"; }

.icon-marker:before {
  content: "\e915"; }

.icon-pobox:before {
  content: "\e916"; }

.custom-radio, .custom-checkbox {
  display: inline-block;
  vertical-align: top;
  margin-top: 1px;
  width: 18px;
  height: 18px;
  border: solid 2px #43165C;
  position: relative;
  margin-right: 8px; }
  .custom-radio:before, .custom-checkbox:before {
    position: absolute;
    left: 50%;
    top: 50%;
    /*transform: translateY(-50%);
    margin: 0 auto;*/
    display: none; }

.custom-radio {
  border-radius: 50%; }
  .custom-radio:before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #FF3662;
    border-radius: 50%;
    margin-top: -3px;
    margin-left: -3px; }

input[type="radio"] {
  display: none; }
  input[type="radio"]:checked + .custom-radio:before {
    display: block; }

.radio-wrapper > label, .checkbox-wrapper > label {
  cursor: pointer;
  font-size: 0; }
  .radio-wrapper > label > i, .radio-wrapper > label > span, .checkbox-wrapper > label > i, .checkbox-wrapper > label > span {
    font-size: 15px; }

.custom-checkbox:before {
  color: #FF3662;
  margin-top: -8px;
  margin-left: -7px; }

input[type="checkbox"] {
  display: none; }
  input[type="checkbox"]:checked + .custom-checkbox:before {
    display: block; }

/*.selectboxit-btn.custom-select--gray {
  background-color: $light-gray;
}

.custom-select--gray {
  + .selectboxit-options {
    background-color: $light-gray !important;
  }
}

.country-select {
  .selectboxit-container  .selectboxit-options {
    width: auto;
    left: 16px;
    right: 16px;
    top: 100%!important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}*/
.jcf-select {
  display: block;
  border: none;
  height: 40px;
  background: none;
  text-align: center; }
  .jcf-select .jcf-select-text {
    position: relative;
    z-index: 0;
    height: 40px;
    line-height: 40px;
    margin: 0;
    font-family: "nunito_sansextrabold";
    font-size: 15px;
    border-radius: 20px;
    border: 1px solid #C6B9CE;
    background: #F7F6F7;
    cursor: pointer; }
    .jcf-select .jcf-select-text > span {
      vertical-align: top;
      line-height: 38px; }
  .jcf-select .jcf-select-opener {
    background: none;
    z-index: 0;
    font-size: 12px; }
    .jcf-select .jcf-select-opener:before {
      display: inline-block;
      width: 45px;
      height: 40px;
      line-height: 42px;
      text-align: center;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      color: #FF3662; }
  .jcf-select .jcf-select-drop {
    z-index: 1;
    top: 25px;
    left: 0;
    right: 0; }
  .jcf-select .jcf-select-drop-content {
    padding: 20px 0 0 0;
    background: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-color: #C6B9CE;
    overflow: hidden; }
  .jcf-select .jcf-list-content {
    width: 100% !important;
    -webkit-overflow-scrolling: touch; }
    .jcf-select .jcf-list-content ul li {
      padding: 0; }
      .jcf-select .jcf-list-content ul li:after {
        display: none; }
      .jcf-select .jcf-list-content ul li > span {
        padding: 5px 30px 5px 20px; }
  .jcf-select .jcf-list {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white; }
  .jcf-select .jcf-option {
    color: #43165C;
    font-family: "nunito_sansregular";
    font-size: 15px;
    cursor: pointer; }
  .jcf-select .jcf-select-drop .jcf-hover {
    background: none; }
  .jcf-select .jcf-select-drop .jcf-selected {
    background: none;
    /* &:before {
      position: absolute;
      right: 0;
      top: 0;
      @extend .iconmoon;
      @extend .icon-check;

    }*/ }
  .jcf-select .jcf-scrollbar-dec, .jcf-select .jcf-scrollbar-inc {
    display: none; }
  .jcf-select .jcf-scrollbar-vertical {
    width: 4px;
    margin-right: 15px;
    background: none;
    bottom: 15px;
    top: 10px;
    height: auto !important; }
    .jcf-select .jcf-scrollbar-vertical .jcf-scrollbar-slider {
      width: 2px;
      background: #EAE7EB;
      margin-left: 0;
      height: 100% !important; }
    .jcf-select .jcf-scrollbar-vertical .jcf-scrollbar-handle {
      width: 4px;
      margin-left: -1px;
      background: purple;
      cursor: pointer;
      border-radius: 10px; }
  .jcf-select .jcf-scrollbar-horizontal {
    display: none; }

.jcf-focus, .jcf-focus * {
  border-color: #C6B9CE !important; }

.bg--white .jcf-select .jcf-select-text, .bg--white .jcf-select .jcf-select-drop-content, .bg--white .jcf-select .jcf-list {
  background: white; }

.bg--lightgray .jcf-select .jcf-select-text {
  background: #F7F6F7; }

.triangle, .header-right-content__item[data-toggle="collapse"][aria-expanded="true"]:after, .header__cart-notification:after, .box .box__buttons--has-triangle:after, .search-list__title > a:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white; }

.bg--lightgray .triangle, .bg--lightgray .header-right-content__item[data-toggle="collapse"][aria-expanded="true"]:after, .bg--lightgray .header__cart-notification:after, .bg--lightgray .box .box__buttons--has-triangle:after, .box .bg--lightgray .box__buttons--has-triangle:after, .bg--lightgray .search-list__title > a:after {
  border-top-color: #F7F6F7; }

.bg--lightgray .triangle--up, .bg--lightgray .search-list__title > a:after {
  border-top: none;
  border-bottom-color: #F7F6F7; }

.triangle--up, .search-list__title > a:after {
  border-top: none;
  border-bottom: 8px solid white; }

fieldset {
  margin-bottom: 25px; }

.form-control {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #C6B9CE;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: none;
  line-height: 20px;
  font-size: 15px;
  appearance: none;
  font-family: "nunito_sansextrabold";
  color: #43165C;
  outline: none; }

.form-group select {
  width: 100% !important; }

.has-error label {
  color: #FF3662 !important; }

.has-error .form-control, .has-error .selectboxit-btn {
  border-color: #FF3662;
  outline: none; }

.has-error .jcf-select .jcf-select-text, .has-error .jcf-select .jcf-select-drop-content {
  border-color: #FF3662; }

.has-error .jcf-focus, .has-error .jcf-focus * {
  border-color: #FF3662 !important; }

.error-text {
  color: #FF3662;
  font-family: "nunito_sansbold";
  font-size: 12px;
  margin-bottom: 5px; }

.bg--lightgray .form-control {
  background-color: #F7F6F7; }

.form-control--small {
  height: 32px;
  padding-left: 10px;
  padding-right: 10px; }

.form-control--qty {
  width: 70px;
  display: inline-block;
  vertical-align: middle;
  text-align: center; }

textarea.form-control {
  padding: 25px;
  font-family: "nunito_sanslight";
  resize: vertical;
  min-height: 180px;
  text-align: left; }

.control-label {
  font-size: 14px;
  font-weight: normal;
  font-family: "nunito_sansitalic"; }

.input-wrapper--has-icon {
  position: relative; }
  .input-wrapper--has-icon .form-control {
    padding-right: 45px; }

.input-wrapper__icon {
  width: 45px;
  height: 40px;
  background: none;
  position: absolute;
  right: 0;
  top: 0; }

.input--required {
  color: red; }

/* PLACEHOLDER, AUTOFILL
-----------------------------------------------------------------------------*/
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #43165C !important;
  font-family: "nunito_sanslight";
  opacity: 1; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #43165C !important;
  font-family: "nunito_sanslight";
  opacity: 1; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #43165C !important;
  font-family: "nunito_sanslight";
  opacity: 1; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #43165C !important;
  font-family: "nunito_sanslight";
  opacity: 1; }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s; }

.search-form__input {
  background: none;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3); }
  .search-form__input:focus {
    border-color: white;
    outline: none;
    box-shadow: none; }

.search-form__icon {
  font-size: 20px;
  margin-top: -5px;
  color: #43165C; }

.form--default .form-control {
  color: #43165C;
  text-align: center; }

.form--default textarea.form-control {
  padding: 25px;
  font-family: "nunito_sanslight";
  text-align: left; }

@media (max-width: 767px) {
  .form--default .form__footer {
    text-align: center; } }

.form--default .form__footer .col-sm-6:last-child {
  text-align: right; }
  @media (max-width: 767px) {
    .form--default .form__footer .col-sm-6:last-child {
      text-align: center; } }

.login-box .box__inner-content {
  padding-bottom: 40px !important; }

.login-box .form__inner-content {
  max-width: 510px;
  margin-left: auto;
  margin-right: auto; }

.form__footer {
  padding-top: 40px;
  font-size: 12px;
  font-family: "nunito_sansregular"; }
  .form__footer p {
    font-size: 12px; }
  .form__footer .link {
    vertical-align: baseline;
    font-family: "nunito_sansbold"; }

#header-search ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: white !important;
  opacity: 1; }

#header-search :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: white !important;
  opacity: 1; }

#header-search ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: white !important;
  opacity: 1; }

#header-search :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white !important;
  opacity: 1; }

#new-delivery-address-form .form-control, #new-address-form .form-control {
  text-align: center; }

/*
 * jquery.selectBoxIt.css 3.8.1
 * Author: @gregfranko
 */
/*
  Common CSS Properties
  ---------------------
  These properties will be applied to any themes that you use
*/
/* SelectBoxIt container */
.selectboxit-container {
  position: relative;
  display: inline-block;
  vertical-align: top; }

/* Styles that apply to all SelectBoxIt elements */
.selectboxit-container * {
  /* Prevents text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  white-space: nowrap; }

/* Button */
.selectboxit-container .selectboxit {
  width: 100% !important;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 3; }

/* Height and Vertical Alignment of Text */
.selectboxit-container span, .selectboxit-container .selectboxit-options a {
  height: 40px;
  /* Height of the drop down */
  line-height: 40px;
  /* Vertically positions the drop down text */
  display: block; }

/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
  outline: 0; }

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled, .selectboxit-options .selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default; }

/* Button Text */
.selectboxit-text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "nunito_sansextrabold";
  font-size: 14px;
  padding: 0 15px;
  width: 100%;
  max-width: 100% !important; }

/* Options List */
.selectboxit-container .selectboxit-options {
  width: 100%;
  max-height: 300px;
  margin: 0;
  padding: 0;
  background: white;
  border-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  list-style: none;
  position: absolute;
  top: 25px !important;
  font-family: "nunito_sansextrabold";
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;
  border: 1px solid #C6B9CE;
  padding-top: 20px;
  padding-bottom: 5px; }
  .selectboxit-container .selectboxit-options .selectboxit-focus {
    color: #43165C;
    background-color: #EAE7EB; }
    .selectboxit-container .selectboxit-options .selectboxit-focus a {
      color: #43165C; }

/* Individual options */
.selectboxit-option .selectboxit-option-anchor {
  padding: 0 15px; }

/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
  text-decoration: none; }

/* Individual Option Optgroup Header */
.selectboxit-option, .selectboxit-optgroup-header {
  margin: 0;
  padding: 0; }
  .selectboxit-option:after, .selectboxit-optgroup-header:after {
    display: none; }

.selectboxit-arrow-container {
  font-size: 0;
  width: 32px;
  height: 38px !important;
  line-height: 38px !important;
  padding-right: 15px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: left; }

/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  color: #43165C;
  font-size: 12px; }

.selectboxit-option-icon-container {
  display: none !important; }

.selectboxit-container .selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle; }

.selectboxit-rendering {
  display: inline-block !important;
  *display: inline !important;
  zoom: 1 !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important; }

/*
  Default Theme
  -------------
  Note: Feel free to remove all of the CSS underneath this line if you are not using the default theme
*/
.selectboxit-btn {
  background-color: white;
  border: 1px solid #C6B9CE; }

.selectboxit-default-arrow {
  width: 0;
  height: 0;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.selectboxit-list {
  background-color: #ffffff; }

.form-group .selectboxit-container {
  width: 100%; }
  .form-group .selectboxit-container .selectboxit-arrow {
    color: #FF3662; }

.table {
  display: table;
  margin: 0; }
  .table .table {
    background: none; }

.table--fixed {
  table-layout: fixed; }

.table--border-spacing, .table--border-spacing-60 {
  border-collapse: separate; }
  .table--border-spacing .table, .table--border-spacing-60 .table {
    border-spacing: 0; }
  .table--border-spacing .table__cell, .table--border-spacing-60 .table__cell {
    padding-left: 0;
    padding-right: 0; }

.table--border-spacing {
  border-spacing: 30px 0; }

.table--border-spacing-60 {
  border-spacing: 60px 0; }

.table__head {
  font-family: "nunito_sansextrabold";
  text-transform: uppercase; }

.table__row {
  display: table-row; }

.table__cell {
  display: table-cell;
  float: none; }
  .table__cell.pr--15 {
    padding-left: 0; }
  .table__cell.pl--15 {
    padding-right: 0; }

.table__cell--vmiddle {
  vertical-align: middle; }

.table__cell--vtop {
  vertical-align: top; }

.table__cell--bottom {
  vertical-align: bottom; }

button, .btn {
  color: white;
  font-family: "nunito_sansextrabold";
  border-radius: 20px;
  text-transform: uppercase;
  border: none; }
  button:hover, button:focus, .btn:hover, .btn:focus {
    color: white;
    outline: none;
    border: none; }
  button:active, button.active, .btn:active, .btn.active {
    box-shadow: none; }
  button:focus, button:active:focus, button:active.focus, button.active:focus, button.active:focus, .btn:focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active:focus {
    outline: none; }
  button span, .btn span {
    font-size: 14px;
    line-height: 20px; }
    @media (max-width: 991px) {
      button span, .btn span {
        font-size: 13px; } }
    @media (max-width: 767px) {
      button span, .btn span {
        font-size: 12px; } }
    @media (max-width: 480px) {
      button span, .btn span {
        font-size: 11px; } }
  button .icon-cart, .btn .icon-cart {
    margin-top: -5px; }
  button .icon-play2, .btn .icon-play2 {
    font-size: 11px; }
  button .icon-eye, .btn .icon-eye {
    font-size: 10px; }
  button [class^="icon-"], button [class*=" icon-"], .btn [class^="icon-"], .btn [class*=" icon-"] {
    margin-right: -5px; }

.btn--small {
  padding: 5px 20px; }
  .btn--small span {
    font-size: 11px; }

.btn--big {
  padding: 9px 25px; }

.btn--login {
  font-size: 14px;
  padding: 4px 25px; }

.btn-default {
  background: none; }
  .btn-default:hover {
    background: none; }

.btn--purple {
  background-color: #43165C; }

.btn--red {
  background-color: #FF3662; }

.btn--blue {
  background-color: #009FE3; }

.btn--transparent {
  background: none;
  border: 1px solid #43165C;
  color: #43165C; }
  .btn--transparent:hover, .btn--transparent:focus {
    color: #43165C;
    border: 1px solid #43165C; }

.btn--transparent-lightpurple {
  background: none;
  border: 1px solid #C6B9CE;
  color: #43165C; }
  .btn--transparent-lightpurple:hover, .btn--transparent-lightpurple:focus {
    color: #43165C;
    border: 1px solid #C6B9CE; }

.btn--inactive {
  pointer-events: none;
  cursor: default;
  background-color: #D9D0DE;
  color: white; }

.btn--no-text-transform {
  font-size: 0;
  text-transform: none; }

.btn--text-regular {
  font-family: "nunito_sansregular"; }

.btn--340 {
  width: 100%;
  max-width: 340px; }
  .btn--340.btn--big {
    padding: 9px; }

.btn--270 {
  width: 100%;
  max-width: 270px; }
  .btn--270.btn--big {
    padding: 9px; }

.btn--section {
  margin-top: 55px; }
  @media (max-width: 1199px) {
    .btn--section {
      margin-top: 35px; } }

.btn-hamburger {
  width: 40px;
  font-size: 0;
  line-height: 0;
  border: none;
  background: none;
  padding: 15px 0; }
  .btn-hamburger.opened .icon-bar {
    width: 28px;
    background: black;
    transition: .5s ease; }
    .btn-hamburger.opened .icon-bar:nth-child(1) {
      transform: rotate(-45deg) translateY(5px) translateX(-5px); }
    .btn-hamburger.opened .icon-bar:nth-child(2) {
      opacity: 0; }
    .btn-hamburger.opened .icon-bar:nth-child(3) {
      transform: rotate(45deg) translateY(-6px) translateX(-6px); }
  .btn-hamburger .icon-bar {
    width: 28px;
    height: 3px;
    background: black;
    transition: .5s ease;
    margin-bottom: 5px; }
    .btn-hamburger .icon-bar:last-child {
      margin-bottom: 0; }
    .btn-hamburger .icon-bar:nth-child(1) {
      transform: rotate(0) translateY(0) translateX(0); }
    .btn-hamburger .icon-bar:nth-child(2) {
      opacity: 1;
      transform: rotate(0) translateY(0) translateX(0); }
    .btn-hamburger .icon-bar:nth-child(3) {
      transform: rotate(0) translateY(0) translateX(0);
      margin-bottom: 0; }

.link {
  display: inline-block;
  vertical-align: middle; }
  .link:hover, .link:focus {
    text-decoration: none; }

.link--purple, .address-list .address-list__item .address-list--delete {
  color: #43165C; }
  .link--purple:hover, .address-list .address-list__item .address-list--delete:hover, .link--purple:focus, .address-list .address-list__item .address-list--delete:focus {
    color: #43165C; }

.link--white {
  color: white; }
  .link--white:hover, .link--white:focus {
    color: white; }

.link--toggle {
  position: relative; }
  .link--toggle span {
    font-family: "nunito_sansextrabold";
    font-size: 14px; }
  .link--toggle i {
    color: #FF3662;
    font-size: 12px; }

.link--toggle-close i {
  color: #43165C; }

a[href^="mailto:"] {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto; }

.breadcrumb-wrapper {
  text-align: center; }

.breadcrumb {
  font-size: 0;
  display: inline-block;
  font-family: "nunito_sanslight";
  padding: 8px 5px;
  border-bottom: 1px solid #C6B9CE;
  border-radius: 0;
  margin-bottom: 35px;
  margin-top: 15px;
  background: none; }
  .breadcrumb a, .breadcrumb span {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    line-height: 16px; }
  .breadcrumb .breadcrumb__item {
    font-size: 0;
    line-height: 0;
    display: inline-block;
    vertical-align: middle;
    padding-left: 0; }
    .breadcrumb .breadcrumb__item:after {
      display: none; }
    .breadcrumb .breadcrumb__item a {
      color: #43165C; }
    .breadcrumb .breadcrumb__item + li:before {
      font-size: 6px;
      color: #43165C !important;
      padding: 0 7px !important; }

.breadcrumb__item--active {
  font-family: "nunito_sansblack"; }

.hero .breadcrumb {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 5px; }
  .hero .breadcrumb a, .hero .breadcrumb span {
    color: white; }

.hero .breadcrumb__item + li:before {
  color: white !important; }

.dropdown-menu {
  width: 100%;
  margin: 0;
  box-shadow: none; }
  .dropdown-menu > li:after {
    display: none; }
  .dropdown-menu > li > a {
    color: #43165C;
    padding: 10px 0; }
    .dropdown-menu > li > a:hover {
      color: #43165C;
      background: none; }
  .dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
    color: #43165C;
    background: none; }

.logo {
  display: block; }

.site-header {
  height: 70px;
  padding: 0 70px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white; }
  @media (max-width: 1400px) {
    .site-header {
      padding: 0 30px; } }
  @media (max-width: 991px) {
    .site-header {
      padding-right: 65px; } }
  @media (max-width: 480px) {
    .site-header {
      padding: 0 55px 0 15px; } }
  .site-header .btn-hamburger {
    padding: 15px 0;
    position: absolute;
    right: 15px;
    height: 70px;
    /*@media(max-width: $screen-xs-max) {
      height: 50px;
    }*/ }
    @media (max-width: 480px) {
      .site-header .btn-hamburger {
        right: 10px; } }

.site-header__inner-content {
  display: table;
  width: 100%; }

.site-header__logo, .header-nav, .header-right-content {
  display: table-cell;
  vertical-align: middle; }

.site-header__logo {
  padding-right: 15px;
  width: 295px; }
  .site-header__logo img {
    width: 295px;
    min-width: 295px; }
    @media (max-width: 1400px) {
      .site-header__logo img {
        width: 200px;
        min-width: 200px; } }
    @media (max-width: 767px) {
      .site-header__logo img {
        width: 125px;
        min-width: 0; } }

.header-nav {
  width: 100%;
  text-align: center;
  /*@media(max-width: $screen-xs-max) {
    top: 50px;
  }*/ }
  @media (min-width: 992px) {
    .header-nav {
      display: table-cell !important; } }
  @media (max-width: 991px) {
    .header-nav {
      display: none;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      background: white;
      padding: 10px 0;
      border-top: 1px solid #e3dce7; } }
  .header-nav .btn--login {
    margin: 10px 0 15px 0; }

.header-nav__item {
  color: #43165C;
  display: inline-block;
  text-transform: uppercase;
  font-family: "nunito_sansextrabold";
  padding: 0 7px;
  font-size: 0; }
  @media (max-width: 991px) {
    .header-nav__item {
      display: block; } }
  .header-nav__item span {
    position: relative;
    font-size: 15px;
    height: 70px;
    line-height: 70px; }
    @media (max-width: 991px) {
      .header-nav__item span {
        height: auto;
        line-height: 40px; } }

.header-nav__item:hover, .header-nav__item:focus, .header-nav__item--active {
  color: #43165C;
  text-decoration: none; }

.header-nav__item:hover span:after, .header-nav__item:focus span:after, .header-nav__item--active span:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #43165C;
  bottom: 15px; }
  @media (max-width: 991px) {
    .header-nav__item:hover span:after, .header-nav__item:focus span:after, .header-nav__item--active span:after {
      bottom: 0; } }

.header-right-content {
  /*width: 320px;
  @media(max-width: $screen-xs-max) {
    width: auto;
  }*/ }

.header-right-inner-content {
  display: table;
  float: right;
  /*@media(min-width: $screen-sm-min) {
    min-width: 210px;
  }*/ }
  @media (max-width: 767px) {
    .header-right-inner-content {
      border-left: 1px solid #E2DCE7; } }

.header-right-content__item {
  position: relative;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-right: 1px solid #E2DCE7;
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px;
  text-align: center; }
  @media (max-width: 480px) {
    .header-right-content__item {
      width: 50px;
      min-width: 50px;
      /*height: 50px;*/ } }
  @media (max-width: 767px) {
    .header-right-content__item:first-child {
      display: none; } }
  .header-right-content__item[data-toggle="collapse"] {
    position: relative; }
    .header-right-content__item[data-toggle="collapse"][aria-expanded="true"]:after {
      content: "";
      position: absolute;
      z-index: 2;
      bottom: -8px;
      left: 50%;
      margin-left: -6px; }
    .header-right-content__item[data-toggle="collapse"][aria-expanded="true"][href="#header-search"] i:before {
      font-size: 17px; }
    .header-right-content__item[data-toggle="collapse"][aria-expanded="true"][href="#header-profil-menu"] i {
      color: #FF3662; }
  .header-right-content__item[href="#header-profil-menu"] {
    position: relative;
    border-left: 1px solid #E2DCE7; }
  .header-right-content__item[href="#header-search"] {
    /*@media(max-width: $screen-xs-max) {
      border-left: 1px solid $light-purple5;
    }*/ }

.header__icon-cart, .header__icon-search, .header__icon-profile {
  font-size: 25px;
  color: #43165C; }
  @media (max-width: 480px) {
    .header__icon-cart, .header__icon-search, .header__icon-profile {
      font-size: 20px; } }
  .header__icon-cart:hover, .header__icon-cart:focus, .header__icon-search:hover, .header__icon-search:focus, .header__icon-profile:hover, .header__icon-profile:focus {
    color: #43165C;
    text-decoration: none; }

#header-search, .header__collapse-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; }
  #header-search .search-form, #header-search .header__collapse-inner-content, .header__collapse-content .search-form, .header__collapse-content .header__collapse-inner-content {
    padding: 15px 70px; }
    @media (max-width: 1400px) {
      #header-search .search-form, #header-search .header__collapse-inner-content, .header__collapse-content .search-form, .header__collapse-content .header__collapse-inner-content {
        padding-left: 30px;
        padding-right: 30px; } }
    @media (max-width: 480px) {
      #header-search .search-form, #header-search .header__collapse-inner-content, .header__collapse-content .search-form, .header__collapse-content .header__collapse-inner-content {
        padding-left: 15px;
        padding-right: 15px; } }
    #header-search .search-form .input-wrapper, #header-search .header__collapse-inner-content .input-wrapper, .header__collapse-content .search-form .input-wrapper, .header__collapse-content .header__collapse-inner-content .input-wrapper {
      margin-right: 80px;
      width: 100%;
      max-width: 365px; }
      @media (max-width: 991px) {
        #header-search .search-form .input-wrapper, #header-search .header__collapse-inner-content .input-wrapper, .header__collapse-content .search-form .input-wrapper, .header__collapse-content .header__collapse-inner-content .input-wrapper {
          margin-right: 120px; } }
      @media (max-width: 767px) {
        #header-search .search-form .input-wrapper, #header-search .header__collapse-inner-content .input-wrapper, .header__collapse-content .search-form .input-wrapper, .header__collapse-content .header__collapse-inner-content .input-wrapper {
          margin-right: 105px;
          max-width: 300px; } }
      @media (max-width: 480px) {
        #header-search .search-form .input-wrapper, #header-search .header__collapse-inner-content .input-wrapper, .header__collapse-content .search-form .input-wrapper, .header__collapse-content .header__collapse-inner-content .input-wrapper {
          float: none !important;
          margin: 0 auto; } }
  #header-search .search-form__icon, .header__collapse-content .search-form__icon {
    color: white; }

@media (max-width: 767px) {
  #header-profil-menu {
    display: none !important; } }

.myprofil-menu__item {
  display: inline-block;
  vertical-align: middle;
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "nunito_sansextrabold";
  padding: 0 15px;
  margin: 10px 0;
  border-left: 1px solid rgba(255, 255, 255, 0.3); }
  .myprofil-menu__item:first-child {
    border-left: none; }
  .myprofil-menu__item:hover, .myprofil-menu__item:focus {
    color: white;
    text-decoration: none; }

.header__cart-notification {
  padding: 25px 70px;
  text-align: right;
  text-transform: uppercase;
  color: white; }
  @media (max-width: 1400px) {
    .header__cart-notification {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (max-width: 480px) {
    .header__cart-notification {
      padding-left: 15px;
      padding-right: 15px; } }
  .header__cart-notification:after {
    content: "";
    position: absolute;
    z-index: 2;
    top: -25px;
    right: 28px;
    margin-left: -6px; }
    @media (max-width: 991px) {
      .header__cart-notification:after {
        right: 64px; } }
  .header__cart-notification p {
    margin: 0;
    font-family: "nunito_sansbold"; }
    @media (max-width: 767px) {
      .header__cart-notification p {
        font-size: 13px;
        line-height: 18px; } }

.site-footer {
  color: #BDADC6;
  padding: 50px 70px 25px 70px; }
  @media (max-width: 1400px) {
    .site-footer {
      padding-left: 30px;
      padding-right: 30px; } }
  .site-footer h2 {
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: "nunito_sansextrabold";
    margin-bottom: 5px;
    color: white; }
  .site-footer a {
    color: #BDADC6; }
    .site-footer a:hover {
      color: white;
      text-decoration: none; }
    .site-footer a.link--white {
      color: white; }
      .site-footer a.link--white:hover {
        color: white; }

.footer__nav {
  width: auto;
  margin: 45px 0 65px 0; }
  @media (max-width: 767px) {
    .footer__nav {
      display: block; }
      .footer__nav:before, .footer__nav:after {
        content: " ";
        display: table; }
      .footer__nav:after {
        clear: both; } }
  @media (max-width: 480px) {
    .footer__nav {
      margin: 35px 0; } }
  .footer__nav .table__cell {
    width: 250px;
    padding: 0 50px;
    border-left: 1px solid #6a4f76; }
    @media (max-width: 1400px) {
      .footer__nav .table__cell {
        padding: 0 25px; } }
    @media (max-width: 767px) {
      .footer__nav .table__cell {
        display: block;
        width: 50%;
        float: left; } }
    @media (max-width: 480px) {
      .footer__nav .table__cell {
        width: 100%;
        float: none;
        padding-left: 0;
        border-left: none; } }
    .footer__nav .table__cell:first-child {
      padding-left: 0;
      border-left: none; }
    .footer__nav .table__cell:last-child {
      padding-right: 0; }
    @media (max-width: 767px) {
      .footer__nav .table__cell:nth-child(3) {
        padding-left: 0;
        border-left: none;
        clear: left; } }
  .footer__nav ul > li {
    padding-left: 11px; }
    .footer__nav ul > li:after {
      line-height: 16px;
      font-size: 8px; }
    .footer__nav ul > li > a {
      font-size: 12px;
      line-height: 16px;
      vertical-align: top; }

.hero {
  position: relative;
  height: 750px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: white; }
  @media (max-width: 767px) {
    .hero {
      height: 500px; } }
  .hero .container {
    max-width: 1110px; }
  .hero h1 {
    margin-bottom: 0; }
  .hero .hero__title {
    font-size: 90px;
    line-height: 75px;
    margin-bottom: 20px;
    /*@media(max-width: $screen-md-max) {
      margin-bottom: 55px;
    }*/ }
    @media (max-width: 991px) {
      .hero .hero__title {
        font-size: 65px;
        line-height: 65px; } }
    @media (max-width: 767px) {
      .hero .hero__title {
        font-size: 50px;
        line-height: 50px; } }
  .hero h2 {
    margin: 10px 0 5px 0; }
  .hero p {
    font-size: 25px;
    line-height: 30px;
    font-family: "nunito_sansextralight";
    margin: 0; }
    @media (max-width: 991px) {
      .hero p {
        font-size: 18px;
        line-height: 22px; } }
  .hero .btn {
    margin-top: 55px; }

.hero--360 {
  height: 360px; }
  @media (max-width: 767px) {
    .hero--360 {
      height: 250px; } }

.hero--under-main .hero__table {
  padding-bottom: 200px; }
  @media (max-width: 767px) {
    .hero--under-main .hero__table {
      padding-bottom: 100px; } }

.hero--under-main-100 .hero__table {
  padding-bottom: 100px; }

.hero__table {
  width: 100%;
  height: 100%; }

.hero__breadcrumb-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%; }

/*.hero__title {
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}*/
.box {
  position: relative; }
  .box .box__buttons, .box .box__inner-content {
    padding-left: 55px;
    padding-right: 55px; }
    @media (max-width: 1199px) {
      .box .box__buttons, .box .box__inner-content {
        padding-left: 30px;
        padding-right: 30px; } }
    @media (max-width: 767px) {
      .box .box__buttons, .box .box__inner-content {
        padding-left: 20px;
        padding-right: 20px; } }
    @media (max-width: 480px) {
      .box .box__buttons, .box .box__inner-content {
        padding-left: 15px;
        padding-right: 15px; } }
  .box .box__inner-content {
    padding-top: 60px; }
  .box .box__buttons {
    padding-top: 35px;
    padding-bottom: 35px; }
    .box .box__buttons .btn {
      margin-bottom: 10px; }
    .box .box__buttons hr {
      margin: 5px 0 10px 0; }
    .box .box__buttons .link {
      padding-right: 15px;
      margin-bottom: 10px; }
  .box .box__buttons--has-triangle {
    position: relative; }
    .box .box__buttons--has-triangle:after {
      content: "";
      position: absolute;
      z-index: 2;
      bottom: -8px; }
  .box .box__top-illustration {
    position: relative;
    z-index: 3;
    margin-top: -140px; }
  @media (max-width: 1400px) {
    .box h2 {
      font-size: 25px;
      line-height: 28px;
      margin-bottom: 20px; } }
  .box.box--nofooter {
    padding-bottom: 50px; }

.box__category {
  font-family: "nunito_sansblack";
  color: #009FE3;
  text-transform: uppercase;
  margin-bottom: 20px; }

.box__category--small {
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 12px; }

.box__price, .box__price-text {
  padding-right: 15px;
  margin-bottom: 10px; }

.box__price {
  font-size: 26px;
  line-height: 30px;
  font-family: "nunito_sansblack"; }

.box__price--small {
  font-size: 22px; }

.box__price--extrasmall {
  font-size: 20px; }

.box__price-text {
  font-size: 10px;
  line-height: 15px;
  color: #009FE3;
  text-transform: uppercase; }

.box--top-gradient {
  padding-top: 15px; }
  .box--top-gradient:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 15px; }

.box--left-gradient {
  padding-left: 15px; }
  .box--left-gradient:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 15px; }

.box--background {
  color: white;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }

.box__collapse-inner-content {
  position: relative;
  background-color: #EAE7EB;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 55px;
  padding-right: 55px; }
  @media (max-width: 1199px) {
    .box__collapse-inner-content {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (max-width: 767px) {
    .box__collapse-inner-content {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 480px) {
    .box__collapse-inner-content {
      padding-left: 15px;
      padding-right: 15px; } }
  .box__collapse-inner-content:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    background: url("../img/shadow-collapse-content.png") left top repeat-x; }

.box__collapse-inner-content--small-pad {
  padding: 30px; }

.box__collapse-close {
  margin-top: 25px; }

.box--form {
  margin-bottom: 125px; }
  .box--form .box__inner-content {
    padding-top: 45px;
    padding-bottom: 55px; }

.top-products-list .box__text-content {
  padding-left: 15px;
  padding-right: 15px; }

.top-products-list .box__img-content {
  margin-bottom: -60px;
  width: 100%; }
  .top-products-list .box__img-content img {
    width: 100%; }

.top-list .box__inner-content, .top-list .box__buttons, .related-products-list .box__inner-content, .related-products-list .box__buttons, .checkout-item-list .box__inner-content, .checkout-item-list .box__buttons {
  padding-left: 45px;
  padding-right: 45px; }
  @media (max-width: 1700px) {
    .top-list .box__inner-content, .top-list .box__buttons, .related-products-list .box__inner-content, .related-products-list .box__buttons, .checkout-item-list .box__inner-content, .checkout-item-list .box__buttons {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (max-width: 767px) {
    .top-list .box__inner-content, .top-list .box__buttons, .related-products-list .box__inner-content, .related-products-list .box__buttons, .checkout-item-list .box__inner-content, .checkout-item-list .box__buttons {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 480px) {
    .top-list .box__inner-content, .top-list .box__buttons, .related-products-list .box__inner-content, .related-products-list .box__buttons, .checkout-item-list .box__inner-content, .checkout-item-list .box__buttons {
      padding-left: 15px;
      padding-right: 15px; } }

.top-list .box__inner-content, .related-products-list .box__inner-content, .checkout-item-list .box__inner-content {
  padding-top: 40px; }

@media (max-width: 1400px) and (min-width: 1200px) {
  .top-list .box__buttons > .table, .related-products-list .box__buttons > .table, .checkout-item-list .box__buttons > .table {
    display: block; } }

@media (max-width: 400px) {
  .top-list .box__buttons > .table, .related-products-list .box__buttons > .table, .checkout-item-list .box__buttons > .table {
    display: block; } }

@media (max-width: 400px) {
  .top-list .box__buttons > .table > .table__cell, .related-products-list .box__buttons > .table > .table__cell, .checkout-item-list .box__buttons > .table > .table__cell {
    display: block; } }

.top-list .box__buttons > .table > .table__cell .pull-right, .related-products-list .box__buttons > .table > .table__cell .pull-right, .checkout-item-list .box__buttons > .table > .table__cell .pull-right {
  display: inline-block; }
  @media (max-width: 400px) {
    .top-list .box__buttons > .table > .table__cell .pull-right, .related-products-list .box__buttons > .table > .table__cell .pull-right, .checkout-item-list .box__buttons > .table > .table__cell .pull-right {
      float: none !important;
      margin-top: 15px;
      margin-bottom: 10px; } }

@media (max-width: 1400px) and (min-width: 1200px) {
  .checkout-item-list .box__buttons > .table {
    display: table; } }

@media (max-width: 1400px) and (min-width: 1200px) {
  .checkout-item-list .box__buttons > .table > .table__cell {
    display: table-cell; } }

.related-products-list {
  /*.box__buttons {
    padding-top: 5px;
  }*/ }
  .related-products-list .box__img-content {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }
  @media (min-width: 1200px) {
    .related-products-list > .row.row--30 {
      margin-top: 30px; } }

@media (max-width: 767px) {
  .academy-hardware-boxes .row--30 {
    margin: 0; } }

@media (max-width: 767px) {
  .academy-hardware-boxes .table--main {
    display: block; } }

@media (max-width: 767px) {
  .academy-hardware-boxes .table--main .box.table__cell {
    display: block; } }

.academy-hardware-boxes .table--main .box.table__cell:first-child {
  margin-bottom: 90px; }

.documents-box h2 {
  margin-right: 55px; }
  @media (max-width: 767px) {
    .documents-box h2 {
      display: block;
      margin-right: 0;
      margin-bottom: 0; } }

.documents-box .box__inner-content {
  padding-bottom: 25px; }
  @media (min-width: 1200px) {
    .documents-box .box__inner-content {
      padding-left: 45px;
      padding-right: 45px; } }

.box--with-video .box__inner-content {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 30px; }
  .box--with-video .box__inner-content .lesson-list__title {
    font-size: 45px;
    line-height: 40px; }
    @media (max-width: 1400px) {
      .box--with-video .box__inner-content .lesson-list__title {
        font-size: 30px;
        line-height: 30px; } }
    .box--with-video .box__inner-content .lesson-list__title .count {
      line-height: 40px; }
      @media (max-width: 1400px) {
        .box--with-video .box__inner-content .lesson-list__title .count {
          line-height: 30px; } }
    @media (max-width: 991px) {
      .box--with-video .box__inner-content .lesson-list__title {
        font-size: 25px;
        line-height: 28px; }
        .box--with-video .box__inner-content .lesson-list__title .count {
          font-size: 35px;
          line-height: 24px; } }
  .box--with-video .box__inner-content .lesson-list__type {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #43165C; }
  @media (max-width: 1400px) {
    .box--with-video .box__inner-content {
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .box--with-video .box__inner-content {
      padding-right: 0; } }
  @media (max-width: 991px) {
    .box--with-video .box__inner-content {
      padding-left: 0; } }

.box--with-video .box__left {
  padding-top: 55px;
  padding-bottom: 30px; }
  @media (max-width: 1400px) {
    .box--with-video .box__left {
      padding-top: 30px; } }
  @media (max-width: 991px) {
    .box--with-video .box__left {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 0; } }

.counter-block {
  counter-reset: block; }

.counter-block__item:before {
  counter-increment: block;
  content: counters(block, "/", decimal-leading-zero) " /";
  padding-right: 7px;
  color: #009FE3;
  float: left; }

h3.counter-block__item.heading-3--small:before {
  line-height: 22px; }

h3.counter-block__item:before {
  font-size: 26px;
  line-height: 22px; }
  @media (max-width: 767px) {
    h3.counter-block__item:before {
      font-size: 22px; } }

@media (min-width: 992px) {
  h2.counter-block__item.heading-2--big:before {
    line-height: 36px; } }

h2.counter-block__item:before {
  font-size: 40px;
  line-height: 30px; }
  @media (max-width: 991px) {
    h2.counter-block__item:before {
      line-height: 28px;
      font-size: 35px; } }
  @media (max-width: 991px) {
    h2.counter-block__item:before {
      line-height: 28px; } }
  @media (max-width: 767px) {
    h2.counter-block__item:before {
      line-height: 24px; } }

.counter-block__text {
  display: table-cell;
  vertical-align: top; }

.counter-block--small h3.counter-block__item:before {
  font-size: 14px;
  line-height: 22px; }
  @media (max-width: 767px) {
    .counter-block--small h3.counter-block__item:before {
      font-size: 14px; } }

.heading-with-count span {
  display: inline; }

.count {
  padding-right: 7px;
  color: #009FE3; }

.list-filter-wrapper--top {
  margin-bottom: 45px; }

.list-filter-wrapper {
  text-align: center; }
  .list-filter-wrapper .jcf-select {
    max-width: 445px;
    margin: 0 auto; }
  .list-filter-wrapper .btn {
    margin-top: 20px; }

.filter-dropdown {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 445px;
  margin: 0 auto; }
  .filter-dropdown.mw--100 {
    max-width: 100%; }

.filter-dropdown__btn {
  position: relative;
  z-index: 3;
  width: 100%;
  padding-left: 45px;
  padding-right: 45px;
  text-transform: none;
  background-color: #F7F6F7;
  font-size: 12px; }
  .filter-dropdown__btn:before {
    display: inline-block;
    width: 45px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    color: #FF3662; }

.bg--white .filter-dropdown__btn {
  background-color: white; }

.filter-dropdown__items {
  text-align: center;
  top: 20px;
  z-index: 1;
  background: white;
  padding: 30px 15px 10px 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-color: #C6B9CE;
  border-top: none; }

.login-form .filter-dropdown {
  max-width: 100%; }
  .login-form .filter-dropdown .filter-dropdown__btn {
    background-color: #FFF; }

.icon-cart {
  position: relative;
  /*&:hover, &:focus {
    color: $purple;
    text-decoration: none;
  }*/ }

.shopping-cart__count {
  width: 16px;
  height: 16px;
  background-color: #FF3662;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 18px;
  font-size: 9px;
  font-family: "nunito_sansbold";
  position: absolute;
  bottom: -5px;
  right: -5px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  display: block;
  position: absolute;
  bottom: 25px;
  width: 100%;
  margin: 0;
  font-size: 0;
  line-height: 0;
  text-align: center; }
  .slick-dots li {
    display: inline-block;
    vertical-align: middle;
    padding-left: 0;
    margin: 5px; }
    .slick-dots li:after {
      display: none; }
  .slick-dots button {
    font-size: 0;
    padding: 0;
    width: 10px;
    height: 10px;
    background-color: #43165C; }
  .slick-dots .slick-active button {
    width: 12px;
    height: 12px;
    background-color: #009FE3; }

.checkout-flow {
  counter-reset: checkout-flow-counter;
  list-style: none;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1199px) {
    .checkout-flow {
      margin-top: 30px; } }

.checkout-flow-item.is-active .checkout-flow-item__link:before {
  background-color: #FF3662; }

.checkout-flow-item.is-disabled .checkout-flow-item__link:before {
  background: none;
  border: solid 1px #c1b2c8;
  color: #c1b2c8; }

.checkout-flow-item.is-disabled .checkout-flow-item__text {
  color: #c1b2c8; }

.checkout-flow-item__link {
  position: relative;
  font-family: "nunito_sansbold";
  display: flex;
  flex-direction: column;
  margin-bottom: 45px; }
  .checkout-flow-item__link:hover {
    text-decoration: none; }
  .checkout-flow-item__link:before {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    background-color: #43165C;
    margin-bottom: 10px;
    color: white;
    counter-increment: checkout-flow-counter;
    content: counter(checkout-flow-counter);
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0; }

.checkout-flow-item__text {
  color: #43165C;
  position: absolute;
  left: -50px;
  right: -50px;
  bottom: -30px; }
  @media (max-width: 1199px) {
    .checkout-flow-item__text {
      position: static;
      margin-top: 10px;
      padding: 0 5px; } }

.checkout-flow__back {
  position: absolute;
  top: 10px; }
  @media (max-width: 1199px) {
    .checkout-flow__back {
      position: static;
      margin-bottom: 30px; } }

.checkout-list, .checkout-item-list {
  padding: 20px 0 120px 0; }

.checkout-list__item {
  padding: 30px 15px;
  margin-top: 30px; }
  .checkout-list__item hr {
    margin: 25px 0; }
  .checkout-list__item h2 {
    font-size: 22px; }

.checkout-item {
  display: flex;
  align-items: center;
  background-color: #F7F6F7; }
  @media (max-width: 767px) {
    .checkout-item {
      display: block; } }
  .checkout-item img {
    max-width: 240px; }
    @media (max-width: 767px) {
      .checkout-item img {
        max-width: 100%; } }

.container--checkout a.text--purple:hover, .container--checkout a.text--purple:focus {
  text-decoration: none;
  color: #43165C; }

.infobox {
  padding: 40px;
  margin-top: 30px; }
  .infobox h3 {
    margin-bottom: 20px; }
  .infobox small {
    font-size: 14px;
    font-family: "nunito_sanslight_italic"; }
  .infobox p {
    margin-bottom: 10px; }

.infobox--big-padding {
  padding: 50px; }

.tag {
  background-color: #009FE3;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 11px;
  text-transform: uppercase;
  display: inline;
  vertical-align: inherit;
  font-family: "nunito_sansbold";
  line-height: 16px; }

.aside-nav__item:after {
  display: none; }

.aside-nav__item a {
  position: relative;
  font-size: 15px;
  color: #43165C;
  text-transform: uppercase;
  font-family: "nunito_sansregular";
  display: block;
  padding: 7px 0;
  padding-left: 20px; }
  .aside-nav__item a:before {
    position: absolute;
    left: 0;
    margin-top: 3px;
    font-size: 11px;
    color: #FF3662;
    display: none; }
  .aside-nav__item a:hover, .aside-nav__item a:focus {
    color: #43165C;
    text-decoration: none; }
  .aside-nav__item a:hover:before {
    display: inline-block; }

.aside-nav__item:first-child a {
  padding-top: 0; }

.aside-nav__item.active a {
  font-family: "nunito_sansextrabold"; }
  .aside-nav__item.active a:before {
    display: inline-block; }

video {
  max-width: 100%; }

.video-description {
  padding-right: 30px; }
  @media (min-width: 481px) {
    .video-description {
      font-size: 18px;
      line-height: 24px; } }
  @media (max-width: 991px) {
    .video-description {
      padding-right: 0;
      margin-bottom: 60px; } }
  @media (max-width: 991px) and (max-width: 767px) {
    .video-description {
      margin-bottom: 30px; } }
  @media (min-width: 481px) {
    .video-description p {
      font-size: 18px;
      line-height: 24px; } }

.video-col {
  padding-right: 0; }
  @media (max-width: 991px) {
    .video-col {
      padding-left: 0;
      margin-top: 30px; } }

.plyr .plyr__play-large {
  width: 80px;
  height: 80px;
  background-image: url("../img//play-button.svg");
  border: none; }
  @media (max-width: 991px) {
    .plyr .plyr__play-large {
      width: 40px;
      height: 40px; } }
  .plyr .plyr__play-large svg {
    display: none; }

@media (min-width: 1200px) {
  .section--video-desc {
    padding-top: 90px; } }

@media (max-width: 767px) {
  .section--video-desc {
    padding: 30px 0 60px 0; } }

.embedvideo {
  max-width: 100%; }

.mCSB_scrollTools {
  width: 30px;
  margin: 10px 0; }
  .mCSB_scrollTools .mCSB_draggerRail {
    background-color: #d5d0d7; }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #43165C; }

.overlay--purple {
  position: relative; }
  .overlay--purple:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #43165C;
    opacity: .8; }
  .overlay--purple > div {
    position: relative;
    z-index: 2; }

@media (max-width: 1199px) {
  .top-list .table--main {
    display: block; } }

@media (max-width: 1199px) {
  .top-list .table--main > .table__cell {
    display: block;
    padding: 0; } }
  @media (max-width: 1199px) and (min-width: 768px) {
    .top-list .table--main > .table__cell {
      margin-bottom: 30px; } }

@media (max-width: 767px) {
  .top-list .box {
    height: auto;
    margin-bottom: 30px; } }

@media (max-width: 480px) {
  .top-list .box {
    margin-bottom: 15px; } }

@media (max-width: 767px) {
  .top-list .table--border-spacing {
    display: block;
    padding: 0 30px; } }

@media (max-width: 480px) {
  .top-list .table--border-spacing {
    display: block;
    padding: 0 15px; } }

@media (max-width: 767px) {
  .top-list .table--border-spacing > .table__cell {
    display: block; } }

.course-list {
  padding-bottom: 50px; }
  .course-list .container {
    max-width: 1380px; }
  .course-list .section--main {
    padding-top: 0;
    padding-bottom: 60px; }
    @media (max-width: 767px) {
      .course-list .section--main {
        padding-bottom: 30px; } }
    @media (max-width: 480px) {
      .course-list .section--main {
        padding-bottom: 15px; } }

.course-list__img {
  height: 450px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .course-list__img img {
    width: 100%; }
  @media (max-width: 767px) {
    .course-list__img {
      background: none !important;
      height: auto; } }

@media (max-width: 991px) {
  .course-list__item > .col-sm-6 {
    width: 100%; } }

@media (min-width: 992px) {
  .course-list__item .box__text-content {
    padding-left: 0; } }

@media (min-width: 768px) {
  .course-list__item .box {
    margin-top: 30px; } }

@media (min-width: 768px) {
  .course-list__item .box, .course-list__item .box__collapse {
    margin-left: -120px; } }

@media (max-width: 991px) {
  .course-list__item .box, .course-list__item .box__collapse {
    margin-left: 0;
    margin-top: 0; } }

.course-list__item .box__inner-content, .course-list__item .box__collapse-inner-content, .course-list__item .box__buttons {
  padding-left: 16%; }
  @media (max-width: 767px) {
    .course-list__item .box__inner-content, .course-list__item .box__collapse-inner-content, .course-list__item .box__buttons {
      padding-left: 80px; } }
  @media (max-width: 480px) {
    .course-list__item .box__inner-content, .course-list__item .box__collapse-inner-content, .course-list__item .box__buttons {
      padding-left: 15px; } }

.course-list__item .box__buttons {
  padding-top: 20px; }

.course-list__item .box__collapse {
  padding-left: 15px; }

.course-list__item h1, .course-list__item h2 {
  position: relative; }
  .course-list__item h1:before, .course-list__item h2:before {
    content: "";
    display: block;
    width: 10%;
    height: 1px;
    background-color: #43165C;
    position: absolute;
    margin-right: 5%;
    margin-top: 14px;
    right: 100%; }
    @media (max-width: 767px) {
      .course-list__item h1:before, .course-list__item h2:before {
        width: 40px;
        position: absolute;
        right: auto;
        left: -60px; } }
    @media (max-width: 480px) {
      .course-list__item h1:before, .course-list__item h2:before {
        display: none; } }

.course-list__item .slick-slider {
  height: 450px;
  overflow: hidden; }
  @media (max-width: 767px) {
    .course-list__item .slick-slider {
      height: auto; } }

.course-list__item .form-control--qty {
  margin-bottom: 10px; }

.course-details__item {
  margin-top: -60px; }
  @media (min-width: 992px) {
    .course-details__item .box {
      margin-left: -60px; } }
  .course-details__item .box__inner-content p {
    font-family: "nunito_sansbold";
    font-size: 18px;
    line-height: 22px; }
  .course-details__item .box__buttons {
    padding-top: 45px;
    padding-bottom: 50px; }
    @media (max-width: 400px) {
      .course-details__item .box__buttons > .table, .course-details__item .box__buttons > .table > .table__cell {
        display: block; } }
  .course-details__item .form-control--qty {
    margin-bottom: 10px; }
  .course-details__item h2:before, .course-details__item h3:before, .course-details__item h4:before, .course-details__item h5:before, .course-details__item h6:before {
    display: none; }

.lesson-list__item {
  padding-top: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid #d5d0d7; }
  .lesson-list__item:first-child {
    padding-top: 0; }

.lesson-list__time, .lesson-list__type {
  font-size: 0; }
  .lesson-list__time i, .lesson-list__type i {
    font-size: 14px;
    margin-right: 10px; }
    .lesson-list__time i.icon-video, .lesson-list__type i.icon-video {
      font-size: 12px; }
  .lesson-list__time b, .lesson-list__type b {
    font-size: 12px;
    font-family: "nunito_sansblack";
    text-transform: uppercase; }

.lesson-list--boxes .lesson-list__item {
  border-bottom: none;
  margin-top: 60px;
  padding-top: 0; }
  @media (max-width: 767px) {
    .lesson-list--boxes .lesson-list__item {
      margin-top: 30px; } }
  @media (max-width: 480px) {
    .lesson-list--boxes .lesson-list__item {
      margin-top: 15px; } }
  .lesson-list--boxes .lesson-list__item:first-child {
    margin-top: 0; }

@media (min-width: 1200px) {
  .lesson-list--boxes .box__inner-content {
    padding-left: 45px;
    padding-right: 45px; } }

@media (max-width: 767px) {
  .lesson-list--boxes .lesson-list__table {
    display: block; } }

.lesson-list--boxes .lesson-list__table > .table__cell {
  padding-left: 25px;
  padding-right: 25px; }
  @media (max-width: 767px) {
    .lesson-list--boxes .lesson-list__table > .table__cell {
      display: block;
      padding: 0; } }
  .lesson-list--boxes .lesson-list__table > .table__cell:first-child {
    border-right: 1px solid #d5d0d7;
    padding-left: 0; }
    @media (max-width: 767px) {
      .lesson-list--boxes .lesson-list__table > .table__cell:first-child {
        border-right: none;
        margin-bottom: 20px; } }
  .lesson-list--boxes .lesson-list__table > .table__cell:last-child {
    padding-right: 0; }

.lesson-list--boxes .lesson-list__table .btn {
  margin-top: 15px; }

.lesson-list__info span {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #43165C; }
  .lesson-list__info span:last-child {
    margin-right: 0;
    padding-right: 0;
    border: none; }

.lesson-list--summary .lesson-list__item {
  border-bottom: none;
  padding-bottom: 0;
  padding-top: 0; }

.lesson-list--small h2 {
  font-size: 14px; }

.lesson-list--small .box__inner-content {
  padding: 40px 30px; }

.lesson-list--small .lesson-list__item {
  padding-top: 15px;
  padding-bottom: 15px; }
  .lesson-list--small .lesson-list__item:last-of-type {
    border-bottom: none;
    padding-bottom: 0; }

.related-products-list .table--main {
  margin-bottom: 0;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: margin; }
  @media (max-width: 1199px) {
    .related-products-list .table--main {
      display: block;
      padding: 0 30px; } }
  @media (max-width: 480px) {
    .related-products-list .table--main {
      padding: 0 15px; } }
  .related-products-list .table--main > .table__cell {
    position: relative; }
    @media (max-width: 1199px) {
      .related-products-list .table--main > .table__cell {
        display: block;
        padding: 0;
        margin-bottom: 60px; } }
    @media (max-width: 767px) {
      .related-products-list .table--main > .table__cell {
        margin-bottom: 30px; } }
    @media (max-width: 480px) {
      .related-products-list .table--main > .table__cell {
        margin-bottom: 15px; } }
    @media (max-width: 767px) {
      .related-products-list .table--main > .table__cell > .table {
        display: block; } }
    @media (max-width: 767px) {
      .related-products-list .table--main > .table__cell > .table > .table__cell {
        display: block; } }

.related-products-list .box__img-content img {
  width: 100%; }

@media (max-width: 767px) {
  .related-products-list .box__img-content {
    background: none !important;
    height: auto; } }

.related-list {
  padding-bottom: 115px; }
  .related-list .section--main {
    padding-bottom: 0; }
    @media (min-width: 1200px) {
      .related-list .section--main {
        padding-top: 90px; } }
  @media (max-width: 1199px) {
    .related-list .btn--section {
      margin-top: 0; } }

.product-info {
  margin-bottom: 70px;
  font-size: 18px;
  line-height: 22px; }
  .product-info p, .product-info ul {
    font-size: 18px;
    line-height: 22px; }
  .product-info ul li {
    margin-bottom: 3px; }
  .product-info strong, .product-info b {
    font-family: "nunito_sansblack";
    vertical-align: baseline; }

.documents-box__btn {
  vertical-align: top;
  margin-bottom: 25px; }

.documents-box__file {
  display: table;
  width: auto;
  padding: 15px 20px;
  border: 1px solid #43165C;
  font-size: 18px;
  color: #43165C;
  /*float: left;
  margin-right: 15px;*/
  margin-bottom: 15px; }
  @media (max-width: 767px) {
    .documents-box__file {
      font-size: 15px;
      padding: 10px 15px; } }
  .documents-box__file:hover {
    color: #43165C;
    text-decoration: none; }

.documents-box__file-icon, .documents-box__file-name, .documents-box__file-type {
  display: table-cell;
  vertical-align: middle;
  text-transform: capitalize; }

.documents-box__file-icon {
  color: #FF3662;
  font-size: 25px; }
  .documents-box__file-icon i {
    vertical-align: top;
    margin-right: 15px; }

.documents-box__file-type {
  font-family: "nunito_sansextrabold_italic";
  text-transform: uppercase; }
  .documents-box__file-type:before {
    content: "|";
    padding: 0 10px;
    font-family: "nunito_sanslight"; }

.myaccount-form .checkbox-wrapper {
  margin: 15px 0 10px 0; }

@media (max-width: 767px) {
  .col-aside {
    padding-bottom: 45px; } }

@media (max-width: 767px) {
  .invoices-wrapper h2 {
    margin-top: 45px; } }

.invoices {
  font-size: 14px;
  padding-top: 25px;
  margin-bottom: 10px;
  font-family: "nunito_sansregular"; }
  @media (max-width: 480px) {
    .invoices {
      display: block; } }
  @media (max-width: 480px) {
    .invoices > .table__row {
      border-bottom: 1px solid #d5d0d7;
      display: block;
      padding-top: 15px;
      padding-bottom: 15px; } }
  @media (max-width: 480px) {
    .invoices > .table__row:first-child {
      padding-top: 0; } }
  @media (max-width: 480px) {
    .invoices > .table__row > .table__row > .table__cell {
      display: block;
      padding: 3px 0; } }
  .invoices .table__cell {
    text-align: right;
    padding: 15px 7px;
    border-bottom: 1px solid #d5d0d7; }
    @media (max-width: 480px) {
      .invoices .table__cell {
        text-align: left;
        border: none; } }
    .invoices .table__cell:first-child {
      text-align: left;
      padding-left: 0; }
    .invoices .table__cell:last-child {
      padding-right: 0; }
  .invoices .table__head {
    font-size: 12px; }
    @media (max-width: 480px) {
      .invoices .table__head {
        display: none; } }
    .invoices .table__head .table__cell {
      padding-top: 5px;
      padding-bottom: 5px; }
  .invoices .invoices__date {
    text-transform: uppercase; }
  .invoices .invoices__price {
    font-family: "nunito_sansextrabold"; }

.address-list {
  margin-top: 50px; }
  .address-list .address-list__item {
    display: table; }
    @media (max-width: 480px) {
      .address-list .address-list__item {
        display: block; } }
    .address-list .address-list__item .address-list__name, .address-list .address-list__item .address-list-remove-btn {
      display: table-cell;
      vertical-align: top; }
      @media (max-width: 480px) {
        .address-list .address-list__item .address-list__name, .address-list .address-list__item .address-list-remove-btn {
          display: block; } }
    .address-list .address-list__item .address-list__name {
      width: 100%;
      padding-right: 30px; }
      @media (max-width: 480px) {
        .address-list .address-list__item .address-list__name {
          padding-right: 0; } }
    .address-list .address-list__item .address-list__remove-btn {
      white-space: nowrap; }
      @media (max-width: 480px) {
        .address-list .address-list__item .address-list__remove-btn {
          margin-top: 25px; } }
    .address-list .address-list__item .address-list--delete:hover, .address-list .address-list__item .address-list--delete:focus {
      text-decoration: none; }

.my-academy-wrapper h1, .my-academy-wrapper .course-list {
  padding-top: 15px; }

.search-list-wrapper h1 {
  font-size: 25px;
  line-height: 25px; }

.search-list-wrapper .related-products-list .table--main {
  margin-bottom: 30px; }
  @media (max-width: 1199px) {
    .search-list-wrapper .related-products-list .table--main {
      margin-bottom: 0; } }
  @media (max-width: 1199px) {
    .search-list-wrapper .related-products-list .table--main > .table__cell {
      margin-bottom: 30px; } }
  @media (max-width: 480px) {
    .search-list-wrapper .related-products-list .table--main > .table__cell {
      margin-bottom: 15px; } }

.search-list {
  padding-top: 45px;
  position: relative; }
  .search-list:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: -30px;
    left: 0;
    width: 100%;
    height: 30px;
    background: url("../img/shadow-top.png") left top repeat-x; }
  .search-list .search-form {
    max-width: 445px;
    margin: 0 auto 30px auto; }

.search-list__title {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: "nunito_sansregular";
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 25px; }
  .search-list__title > a {
    position: relative;
    display: inline;
    color: #43165C; }
    .search-list__title > a:after {
      display: none;
      content: "";
      position: absolute;
      z-index: 3;
      left: 50%;
      margin-left: -6px;
      bottom: -28px; }
    .search-list__title > a:hover {
      text-decoration: none; }

.search-list__title--active {
  font-family: "nunito_sansblack"; }
  .search-list__title--active > a:after {
    display: inline-block; }

.contact-info {
  font-family: "nunito_sansregular"; }
  .contact-info:after {
    display: none;
    margin-bottom: 0 !important;
    margin-top: 10px !important;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 767px) {
      .contact-info:after {
        display: block; } }
  @media (max-width: 767px) {
    .contact-info {
      display: block;
      text-align: center; } }
  @media (max-width: 767px) {
    .contact-info .table__row {
      display: block; } }
  .contact-info .table__cell {
    padding-bottom: 20px; }
    @media (max-width: 767px) {
      .contact-info .table__cell {
        display: inline-block; } }
    .contact-info .table__cell:first-child {
      width: 25px; }
  .contact-info .icon-phone {
    font-size: 16px; }
  .contact-info .icon-mail {
    font-size: 12px; }
  .contact-info .icon-marker {
    font-size: 20px; }
  .contact-info .icon-pobox {
    font-size: 17px;
    margin-top: -5px; }

.box--contact h2 {
  margin-bottom: 10px; }

.contact-form .form__inner-content {
  padding-top: 20px; }
