.checkout-list, .checkout-item-list {
  padding: 20px 0 120px 0;
}

.checkout-list__item {
  padding: 30px 15px;
  margin-top: 30px;
  hr {
    margin: 25px 0;
  }
  h2 {
    font-size: 22px;
  }
}

.checkout-item {
  display: flex;
  align-items: center;
  background-color: $light-gray;
  @media (max-width: $screen-xs-max) {
    display: block;
  }
  img {
    max-width: 240px;
    @media (max-width: $screen-xs-max) {
      max-width: 100%;
    }
  }
}

.container--checkout {
  a.text--purple {
    &:hover, &:focus {

      text-decoration: none;
      color: $purple;
    }
  }
}
