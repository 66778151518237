.product-info {
  margin-bottom: 70px;
  font-size: 18px;
  line-height: 22px;
  p, ul {
    font-size: 18px;
    line-height: 22px;
  }
  ul {
    li {
      margin-bottom: 3px;
    }
  }
  strong, b {
    font-family: $font-black;
    vertical-align: baseline;
  }
}
