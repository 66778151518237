.course-list {
  padding-bottom: 50px;
  .container {
    max-width: 1380px;
  }
  .section--main {
    padding-top: 0;
    padding-bottom: 60px;
    @media(max-width: $screen-xs-max) {
     padding-bottom: 30px;
    }
    @media(max-width: $screen-xxs-max) {
      padding-bottom: 15px;
    }
  }
}

.course-list__img {
  height: 450px;
  //background-size: auto 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  img {
    width: 100%;
  }
  @media(max-width: $screen-xs-max ){
    background: none!important;
    height: auto;
  }
}


.course-list__item {
  > .col-sm-6 {
    @media(max-width: $screen-sm-max) {
      width: 100%;
    }
  }
  .box__text-content {
    @media(min-width: $screen-md-min) {
      padding-left: 0;
    }
  }
  .box {
    @media(min-width: $screen-sm-min) {
      margin-top: 30px;
    }
  }
  .box, .box__collapse {
    @media(min-width: $screen-sm-min) {
      margin-left: -120px;
    }
    @media(max-width: $screen-sm-max) {
      margin-left: 0;
      margin-top: 0;
    }
  }
  .box__inner-content, .box__collapse-inner-content, .box__buttons {
    padding-left: 16%;
    @media(max-width: $screen-xs-max) {
      padding-left: 80px;
    }
    @media(max-width: $screen-xxs-max) {
      padding-left: 15px;
    }
  }
  .box__buttons {
    padding-top: 20px;
  }
  .box__collapse {
    padding-left: 15px;
  }
  h1, h2 {
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 10%;
      height: 1px;
      background-color: #43165C;
      position: absolute;
      margin-right: 5%;
      margin-top: 14px;
      right: 100%;
      @media(max-width: $screen-xs-max) {
        width: 40px;
        position: absolute;
        right: auto;
        left: -60px;
      }
      @media(max-width: $screen-xxs-max) {
        display: none;
      }
    }
  }
  .slick-slider {
    height: 450px;
    overflow: hidden;
    @media(max-width: $screen-xs-max ){
      height: auto;
    }
  }
  .form-control--qty {
    margin-bottom: 10px;
  }
}

