// Linear gradient

@mixin gradient($from, $to, $fromOffset:0%, $toOffset:100%) {
  background-color: mix($from,$to,50%);
  background-image: -ms-linear-gradient(top, $from $fromOffset, $to $toOffset);
  background-image: -moz-linear-gradient(top, $from $fromOffset, $to $toOffset);
  background-image: -o-linear-gradient(top, $from $fromOffset, $to $toOffset);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, $from), color-stop($toOffset/100, $to));
  background-image: -webkit-linear-gradient(top, $from $fromOffset, $to $toOffset);
  background-image: linear-gradient(to bottom, $from $fromOffset, $to $toOffset);
}

@mixin gradientH($from, $to, $fromOffset:0%, $toOffset:100%) {
  background-color: mix($from,$to,50%);
  background-image: -ms-linear-gradient(right, $from $fromOffset, $to $toOffset);
  background-image: -moz-linear-gradient(right, $from $fromOffset, $to $toOffset);
  background-image: -o-linear-gradient(right, $from $fromOffset, $to $toOffset);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, $from), color-stop($toOffset/100, $to));
  background-image: -webkit-linear-gradient(left, $from $fromOffset, $to $toOffset);
  background-image: linear-gradient(to right, $from $fromOffset, $to $toOffset);
}

@mixin gradientDeg($deg, $from, $to, $fromOffset:0%, $toOffset:100%) {
  background-color: mix($from,$to,50%);
  background-image: -ms-linear-gradient($deg, $from $fromOffset, $to $toOffset);
  background-image: -moz-linear-gradient($deg, $from $fromOffset, $to $toOffset);
  background-image: -o-linear-gradient($deg, $from $fromOffset, $to $toOffset);
  background-image: -webkit-linear-gradient($deg, $from $fromOffset, $to $toOffset);
  background-image: linear-gradient($deg, $from $fromOffset, $to $toOffset);
}


@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

@mixin word-wrap() {
  word-break:     break-word;
  -webkit-hyphens: auto;
  -moz-hyphens:    auto;
  hyphens:         auto;
}

