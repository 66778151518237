/* colors */
$blue:                #009FE3;
$purple:              #43165C;

$light-purple:        #6a4f76;
$light-purple2:       #BDADC6;
$light-purple3:       #C6B9CE;
$light-purple4:       #D9D0DE;
$light-purple5:       #E2DCE7;
$light-purple6:       #c1b2c8;

$red:                 #FF3662;
$red2:                #f43462;
$dark-red:            #9e255f;

$gray:                #d5d0d7;
$light-gray:          #F7F6F7;
$light-gray2:         #e3dce7;
$light-gray3:         #EAE7EB;


/* urls */
$img_url:            "../img/";
$font_url:           "../fonts/";


/* fonts */
$font-light: 'nunito_sanslight';
$font-extralight: 'nunito_sansextralight';
$font-lightitalic: 'nunito_sanslight_italic';
$font-italic: 'nunito_sansitalic';
$font-extrabolditalic: 'nunito_sansextrabold_italic';
$font-regular: 'nunito_sansregular';
$font-black: 'nunito_sansblack';
$font-blackitalic: 'nunito_sansblack_italic';
$font-extrabold: 'nunito_sansextrabold';
$font-bold: 'nunito_sansbold';




/* grids */
$screen-xxs-max:      480px;
$screen-xs-min:       481px;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;







