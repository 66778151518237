.gradientH {
  @include gradientH($purple, $red2, 60%, 100%)
}

.gradientV {
  @include gradient($purple, $red2, 60%, 100%)
}

.gradientD {
  @include gradientDeg(45deg, $purple, $dark-red, 50%, 110%);
}