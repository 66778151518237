// default
//
.box {
  position: relative;
  .box__buttons, .box__inner-content {
    padding-left: 55px;
    padding-right: 55px;
    @media(max-width: $screen-md-max) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media(max-width: $screen-xs-max) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media(max-width: $screen-xxs-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .box__inner-content {
    padding-top: 60px;
  }
  .box__buttons {
    padding-top: 35px;
    padding-bottom: 35px;
    .btn {
      margin-bottom: 10px;
    }
    hr {
      margin: 5px 0 10px 0;
    }
    .link {
      padding-right: 15px;
      margin-bottom: 10px;
    }

  }
  .box__buttons--has-triangle {
    position: relative;
    &:after {
      content: "";
      @extend .triangle;
      position: absolute;
      z-index: 2;
      bottom: -8px;
    }
  }
  .box__top-illustration {
    position: relative;
    z-index: 3;
    margin-top: -140px;
  }
  h2 {
    @media(max-width: 1400px) {
      font-size: 25px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
  &.box--nofooter {
    padding-bottom: 50px;
  }
}



// box components
//

.box__category {
  font-family: $font-black;
  color: $blue;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.box__category--small {
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 12px;
}

.box__price, .box__price-text {
  padding-right: 15px;
  margin-bottom: 10px;
}

.box__price {
  font-size: 26px;
  line-height: 30px;
  font-family: $font-black;
}

.box__price--small {
  font-size: 22px;
}

.box__price--extrasmall {
  font-size: 20px;
}

.box__price-text {
  font-size: 10px;
  line-height: 15px;
  color: $blue;
  text-transform: uppercase;
}


// box top/left gradients
//

.box--top-gradient {
  padding-top: 15px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 15px;
    @extend .gradientH;
  }
}

.box--left-gradient {
  padding-left: 15px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 15px;
    @extend .gradientV;
  }
}

.box--background {
  color: white;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}


// box collapse
//

.box__collapse-inner-content {
  position: relative;
  background-color: $light-gray3;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 55px;
  padding-right: 55px;
  @media(max-width: $screen-md-max) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media(max-width: $screen-xs-max) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media(max-width: $screen-xxs-max) {
    padding-left: 15px;
    padding-right: 15px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    background: url('#{$img_url}shadow-collapse-content.png') left top repeat-x;
  }
}

.box__collapse-inner-content--small-pad {
  padding: 30px;
}

.box__collapse-close {
  margin-top: 25px;
}


// form boxes
.box--form {
  margin-bottom:125px;
  .box__inner-content {
    padding-top: 45px;
    padding-bottom: 55px;
  }
}


// top list boxes
//

.top-products-list {
  .box__text-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .box__img-content {
    margin-bottom: -60px;
    width: 100%;
    img {
      width: 100%;
    }
  }
}

.top-list, .related-products-list, .checkout-item-list {
  .box__inner-content, .box__buttons {
    padding-left: 45px;
    padding-right: 45px;
    @media(max-width: 1700px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media(max-width: $screen-xs-max) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media(max-width: $screen-xxs-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .box__inner-content {
    padding-top: 40px;
  }
  .box__buttons {
    > .table {
      @media(max-width: 1400px) {
        @media(min-width: $screen-lg-min) {
          display: block;
        }
      }
      @media(max-width: 400px) {
        display: block;
      }
      > .table__cell {
        @media(max-width: 400px) {
          display: block;
        }
        .pull-right {
          display: inline-block;
          @media(max-width: 400px) {
            float: none!important;
            margin-top: 15px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.checkout-item-list {
  .box__buttons {
    > .table {
      @media(max-width: 1400px) {
        @media(min-width: $screen-lg-min) {
          display: table;
        }
      }
      > .table__cell {
        @media(max-width: 1400px) {
          @media(min-width: $screen-lg-min) {
            display: table-cell;
          }
        }
      }
    }
  }
}



// related-products boxes
//

.related-products-list {
  .box__img-content {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  > .row.row--30 {
    @media(min-width: $screen-lg-min) {
      margin-top: 30px;
    }
  }
  .btn--section {
    //margin: 0;
  }
  /*.box__buttons {
    padding-top: 5px;
  }*/
}



// academy/hardware boxes
//

.academy-hardware-boxes {
  .row--30 {
    @media(max-width: $screen-xs-max) {
      margin: 0;
    }
  }
  .table--main {
    @media(max-width: $screen-xs-max) {
      display: block;
    }
    .box.table__cell {
      @media(max-width: $screen-xs-max) {
        display: block;
      }
      &:first-child {
        margin-bottom: 90px;
      }
    }
  }
}


// downloads documents
//

.documents-box {
  h2 {
    margin-right: 55px;
    @media(max-width: $screen-xs-max) {
      display: block;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  .box__inner-content {
    padding-bottom: 25px;
    @media(min-width: $screen-lg-min) {
      padding-left: 45px;
      padding-right: 45px;
    }
  }
}

.box--with-video {
  .box__inner-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 30px;
    .lesson-list__title {
      font-size: 45px;
      line-height: 40px;
      @media (max-width: 1400px) {
        font-size: 30px;
        line-height: 30px;
      }
      .count {
        line-height: 40px;
        @media (max-width: 1400px) {
          line-height: 30px;
        }
      }
      @media(max-width: $screen-sm-max) {
        font-size: 25px;
        line-height: 28px;
        .count {
          font-size: 35px;
          line-height: 24px;
        }
      }
    }
    .lesson-list__type {
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid $purple;
    }
    @media (max-width: 1400px) {
      padding-left: 15px;
    }
    @media(min-width: $screen-lg-min) {
      padding-right: 0;
    }
    @media (max-width: $screen-sm-max) {
      padding-left: 0;
    }
  }
  .box__left {
    padding-top: 55px;
    padding-bottom: 30px;
    @media (max-width: 1400px) {
      padding-top: 30px;
    }
    @media (max-width: $screen-sm-max) {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 0;
    }
  }
}
