.search-list-wrapper {
  h1 {
    font-size: 25px;
    line-height: 25px;
  }
  .related-products-list {
    .table--main {
      margin-bottom: 30px;
      @media(max-width: $screen-md-max) {
        margin-bottom: 0;
      }
      > .table__cell {
        @media(max-width: $screen-md-max) {
          margin-bottom: 30px;
        }
        @media(max-width: $screen-xxs-max) {
          margin-bottom: 15px;
        }
      }
    }
  }
}


.search-list {
  padding-top: 45px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: -30px;
    left: 0;
    width: 100%;
    height: 30px;
    background: url('#{$img_url}shadow-top.png') left top repeat-x;
  }
  .search-form {
    max-width: 445px;
    margin: 0 auto 30px auto;
  }

}

.search-list__title {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: $font-regular;
  text-transform: uppercase;
  display: inline-block;

  margin-bottom: 0;
  padding-bottom: 25px;

  > a {
    position: relative;
    display: inline;
    color: $purple;
    &:after {
      display: none;
      content: "";
      @extend .triangle;
      @extend .triangle--up;
      position: absolute;
      z-index: 3;
      left: 50%;
      margin-left: -6px;
      bottom: -28px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}


.search-list__title--active {
  font-family: $font-black;
  > a {
    &:after {
      display: inline-block;
    }
  }
}