.table {
  display: table;
  margin: 0;
  .table {
    background: none;
  }
}

.table--fixed {
  table-layout: fixed;
}

.table--border-spacing, .table--border-spacing-60 {
  border-collapse: separate;
  .table {
    border-spacing: 0;
  }
  .table__cell {
    padding-left: 0;
    padding-right: 0;
  }
}

.table--border-spacing {
  border-spacing: 30px 0;
}

.table--border-spacing-60 {
  border-spacing: 60px 0;
}

.table__head {
  font-family: $font-extrabold;
  text-transform: uppercase;
}

.table__row {
  display: table-row;
}

.table__cell {
  display: table-cell;
  float: none;
  &.pr--15 {
    padding-left: 0;
  }
  &.pl--15 {
    padding-right: 0;
  }
}

.table__cell--vmiddle {
  vertical-align: middle;
}

.table__cell--vtop {
  vertical-align: top;
}

.table__cell--bottom {
  vertical-align: bottom;
}
