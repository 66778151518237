.documents-box__btn {
  vertical-align: top;
  margin-bottom: 25px;
}

.documents-box__file {
  display: table;
  width: auto;
  padding: 15px 20px;
  border: 1px solid $purple;
  font-size: 18px;
  color: $purple;
  /*float: left;
  margin-right: 15px;*/
  margin-bottom: 15px;
  @media(max-width: $screen-xs-max) {
    font-size: 15px;
    padding: 10px 15px;
  }
  &:hover {
    color: $purple;
    text-decoration: none;
  }
}

.documents-box__file-icon, .documents-box__file-name, .documents-box__file-type {
  display: table-cell;
  vertical-align: middle;
  text-transform: capitalize;
}

.documents-box__file-icon {
  color: $red;
  font-size: 25px;
  i {
    vertical-align: top;
    margin-right: 15px;
  }
}

.documents-box__file-type {
  font-family: $font-extrabolditalic;
  text-transform: uppercase;
  &:before {
    content: "|";
    padding: 0 10px;
    font-family: $font-light;
  }
}


