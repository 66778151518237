.course-details__item {
  margin-top: -60px;
  .box {
    @media(min-width: $screen-md-min) {
      margin-left: -60px;
    }
  }
  .box__inner-content {
    p {
      font-family: $font-bold;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .box__buttons {
    padding-top: 45px;
    padding-bottom: 50px;
    > .table, > .table > .table__cell {
      @media(max-width: 400px) {
        display: block;
      }
    }
  }
  .form-control--qty {
    margin-bottom: 10px;
  }
  h2, h3, h4, h5, h6 {
    &:before {
      display: none;
    }
  }
}

