fieldset {
  margin-bottom: 25px;
}

.form-control {
  height: 40px;
  //line-height: 40px;
  border-radius: 20px;
  border: 1px solid $light-purple3;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: none;
  line-height: 20px;
  font-size: 15px;
  appearance: none;
  font-family: $font-extrabold;
  color: $purple;
  outline: none;
}

.form-group {
  select {
    width: 100%!important;
  }
}

.has-error {
  label {
    color: $red!important;
  }
  .form-control, .selectboxit-btn {
    border-color: $red;
    outline: none;
  }
  .jcf-select .jcf-select-text, .jcf-select .jcf-select-drop-content {
    border-color: $red;
  }
  .jcf-focus, .jcf-focus * {
    border-color: $red!important;
  }
}

.error-text {
  color: $red;
  font-family: $font-bold;
  font-size: 12px;
  margin-bottom: 5px;
}

.bg--lightgray {
  .form-control {
    background-color: $light-gray;
  }
}

.form-control--small {
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
}

.form-control--qty {
  width: 70px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

textarea {
  &.form-control {
    padding: 25px;
    font-family: $font-light;
    resize: vertical;
    min-height: 180px;
    text-align: left;
  }
}

.control-label{
  font-size: 14px;
  font-weight: normal;
  font-family: $font-italic;
}

.input-wrapper--has-icon {
  position: relative;
  .form-control {
    padding-right: 45px;
  }
}

.input-wrapper__icon {
  width: 45px;
  height: 40px;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
}

.input--required{
  color:red;
}


/* PLACEHOLDER, AUTOFILL
-----------------------------------------------------------------------------*/
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    $purple!important;
  font-family: $font-light;
  opacity: 1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    $purple!important;
  font-family: $font-light;
  opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    $purple!important;
  font-family: $font-light;
  opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    $purple!important;
  font-family: $font-light;
  opacity: 1;
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}


// search form
.search-form__input {
  background: none;
  color: white;
  border: 1px solid rgba(255,255,255,0.3);
  &:focus {
    border-color: white;
    outline: none;
    box-shadow: none;
  }
}

.search-form__icon {
  font-size: 20px;
  margin-top: -5px;
  color: $purple;
}

// login form

.form--default {
  .form-control {
    color: $purple;
    text-align: center;
  }
  textarea {
    &.form-control {
      padding: 25px;
      font-family: $font-light;
      text-align: left;
    }
  }
  .form__footer {
    @media(max-width: $screen-xs-max) {
      text-align: center;
    }
    .col-sm-6 {
      &:last-child {
        text-align: right;
        @media(max-width: $screen-xs-max) {
          text-align: center;
        }
      }
    }
  }
}

.login-box {
  .box__inner-content {
    padding-bottom: 40px!important;
  }
  .form__inner-content {
    max-width: 510px;
    margin-left: auto;
    margin-right: auto;
  }
}

.form__footer {
  padding-top: 40px;
  font-size: 12px;
  font-family: $font-regular;
   p {
     font-size: 12px;
   }
  .link {
    vertical-align:baseline;
    font-family: $font-bold;
  }
}


#header-search {
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    white!important;
    opacity: 1;

  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    white!important;
    opacity: 1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    white!important;
    opacity: 1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    white!important;
    opacity: 1;
  }
}


#new-delivery-address-form, #new-address-form {
  .form-control {
    text-align: center;
  }
}
