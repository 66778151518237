.site-footer {
  color: $light-purple2;
  padding: 50px 70px 25px 70px;
  @extend .gradientD;
  @media(max-width: 1400px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  h2 {
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: $font-extrabold;
    margin-bottom: 5px;
    color: white;
  }
  a {
    color: $light-purple2;
    &:hover {
      color: white;
      text-decoration: none;
    }
    &.link--white {
      color: white;
      &:hover {
        color: white;
      }
    }
  }
}

.footer__nav {
  width: auto;
  margin: 45px 0 65px 0;
  @media(max-width: $screen-xs-max) {
    display: block;
    @include clearfix();
  }
  @media(max-width: $screen-xxs-max) {
    margin: 35px 0;
  }
  .table__cell {
    width: 250px;
    padding: 0 50px;
    border-left: 1px solid $light-purple;
    @media(max-width: 1400px) {
      padding: 0 25px;
    }
    @media(max-width: $screen-xs-max) {
      display: block;
      width: 50%;
      float: left;
    }
    @media(max-width: $screen-xxs-max) {
      width: 100%;
      float: none;
      padding-left: 0;
      border-left: none;
    }
    &:first-child {
      padding-left: 0;
      border-left: none;
    }
    &:last-child {
      padding-right: 0;
    }
    &:nth-child(3) {
      @media(max-width: $screen-xs-max) {
        padding-left: 0;
        border-left: none;
        clear: left;
      }
    }
  }
  ul > li {
    padding-left: 11px;
    &:after {
      line-height: 16px;
      font-size: 8px;
    }
    > a {
      font-size: 12px;
      line-height: 16px;
      vertical-align: top;
    }
  }
}
