[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
}

.icon--left {
  margin-right: 5px;
}

.icon--right {
  margin-left: 5px;
}

.icon-logout {
  margin-top: -3px;
}

.iconmoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: 'icomoon';
  src:  url('#{$font_url}icons/fonts/icomoon.eot?7b6q2t');
  src:  url('#{$font_url}icons/fonts/icomoon.eot?7b6q2t#iefix') format('embedded-opentype'),
  url('#{$font_url}icons/fonts/icomoon.ttf?7b6q2t') format('truetype'),
  url('#{$font_url}icons/fonts/icomoon.woff?7b6q2t') format('woff'),
  url('#{$font_url}icons/fonts/icomoon.svg?7b6q2t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-delete:before {
  content: "\e902";
}
.icon-arrowleft:before {
  content: "\e903";
}
.icon-arrowright:before {
  content: "\e904";
}
.icon-arrowup:before {
  content: "\e905";
}
.icon-arrowdown:before {
  content: "\e906";
}
.icon-cart:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e909";
}
.icon-time:before {
  content: "\e90a";
}
.icon-edit:before {
  content: "\e90b";
}
.icon-play:before {
  content: "\e900";
}
.icon-file:before {
  content: "\e901";
}
.icon-download:before {
  content: "\e90c";
}
.icon-play2:before {
  content: "\e90d";
}
.icon-profile:before {
  content: "\e90e";
}
.icon-plus:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e910";
}
.icon-logout:before {
  content: "\e911";
}
.icon-eye:before {
  content: "\e912";
}
.icon-phone:before {
  content: "\e913";
}
.icon-mail:before {
  content: "\e914";
}
.icon-marker:before {
  content: "\e915";
}
.icon-pobox:before {
  content: "\e916";
}
