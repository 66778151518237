.site-header {
  height: 70px;
  padding: 0 70px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  @media(max-width: 1400px) {
    padding: 0 30px;
  }
  @media(max-width: $screen-sm-max) {
    padding-right: 65px;
  }
  @media(max-width: $screen-xxs-max) {
    padding: 0 55px 0 15px;
  }
  .btn-hamburger {
    padding: 15px 0;
    position: absolute;
    right: 15px;
    height: 70px;
    /*@media(max-width: $screen-xs-max) {
      height: 50px;
    }*/
    @media(max-width: $screen-xxs-max) {
      right: 10px;
    }
  }
}

.site-header__inner-content {
  display: table;
  width: 100%;
}

.site-header__logo, .header-nav, .header-right-content {
  display: table-cell;
  vertical-align: middle;
}

.site-header__logo {
  padding-right: 15px;
  width: 295px;
  img {
    width: 295px;
    min-width: 295px;
    @media(max-width: 1400px) {
      width: 200px;
      min-width: 200px;
    }
    @media(max-width: $screen-xs-max) {
      width: 125px;
      min-width: 0;
    }
  }

}


.header-nav {
  width: 100%;
  text-align: center;
  @media(min-width: $screen-md-min) {
    display: table-cell!important;
  }
  @media(max-width: $screen-sm-max) {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background: white;
    padding: 10px 0;
    border-top: 1px solid $light-gray2;
  }
  /*@media(max-width: $screen-xs-max) {
    top: 50px;
  }*/
  .btn--login {
    margin: 10px 0 15px 0;
  }
}

.header-nav__item {
  color: $purple;
  display: inline-block;
  text-transform: uppercase;
  font-family: $font-extrabold;
  padding: 0 7px;
  font-size: 0;
  @media(max-width: $screen-sm-max) {
    display: block;
  }
  span {
    position: relative;
    font-size: 15px;
    height: 70px;
    line-height: 70px;
    @media(max-width: $screen-sm-max) {
      height: auto;
      line-height: 40px;
    }
  }
}

.header-nav__item:hover, .header-nav__item:focus, .header-nav__item--active {
  color: $purple;
  text-decoration: none;
}

.header-nav__item:hover, .header-nav__item:focus, .header-nav__item--active {
  span {
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: $purple;
      bottom: 15px;
      @media(max-width: $screen-sm-max) {
        bottom: 0;
      }
    }
  }
}

.header-right-content {
  /*width: 320px;
  @media(max-width: $screen-xs-max) {
    width: auto;
  }*/
}

.header-right-inner-content {
  display: table;
  float: right;
  /*@media(min-width: $screen-sm-min) {
    min-width: 210px;
  }*/
  @media(max-width: $screen-xs-max) {
    border-left: 1px solid $light-purple5;
  }
}

.header-right-content__item {
  position: relative;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-right: 1px solid $light-purple5;
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px;
  text-align: center;
  @media(max-width: $screen-xxs-max) {
    width: 50px;
    min-width: 50px;
    /*height: 50px;*/
  }
  &:first-child {
    @media(max-width: $screen-xs-max) {
      display: none;
    }
  }
  &[data-toggle="collapse"] {
    position: relative;
    &[aria-expanded="true"] {
      &:after {
        content: "";
        @extend .triangle;
        position: absolute;
        z-index: 2;
        bottom: -8px;
        left: 50%;
        margin-left: -6px;
      }
      &[href="#header-search"] {
        i {
          &:before {
            @extend .iconmoon;
            @extend .icon-close;
            font-size: 17px;
          }
        }
      }
      &[href="#header-profil-menu"] {
        i {
          color: $red;
        }
      }
    }
  }
  &[href="#header-profil-menu"] {
    position: relative;
    border-left: 1px solid $light-purple5;
  }
  &[href="#header-search"] {
    //display: none;
    /*@media(max-width: $screen-xs-max) {
      border-left: 1px solid $light-purple5;
    }*/


  }
}

.header__icon-cart, .header__icon-search, .header__icon-profile {
  font-size: 25px;
  color: $purple;
  @media(max-width: $screen-xxs-max) {
    font-size: 20px;
  }
  &:hover, &:focus {
    color: $purple;
    text-decoration: none;
  }
}






// header search, header profil menu
//

#header-search, .header__collapse-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  @extend .gradientH;
  .search-form, .header__collapse-inner-content {
    padding: 15px 70px;
    @media(max-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media(max-width: $screen-xxs-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
    .input-wrapper,  {
      margin-right: 80px;
      width: 100%;
      max-width: 365px;
      @media(max-width: $screen-sm-max) {
        margin-right: 120px;
      }
      @media(max-width: $screen-xs-max) {
        margin-right: 105px;
        max-width: 300px;
      }
      @media(max-width: 480px) {
        float: none!important;
        margin: 0 auto;
      }
    }
  }
  .search-form__icon {
    color: white;
  }
}

#header-profil-menu {
  @media(max-width: $screen-xs-max) {
    display: none!important;
  }
}

.myprofil-menu__item {
  display: inline-block;
  vertical-align: middle;
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  font-family: $font-extrabold;
  padding: 0 15px;
  margin: 10px 0;
  border-left: 1px solid rgba(255,255,255, 0.3);
  &:first-child {
    border-left: none;
  }
  &:hover, &:focus {
    color: white;
    text-decoration: none;
  }
}

.header__cart-notification {
  padding: 25px 70px;
  text-align: right;
  text-transform: uppercase;
  color: white;
  @media(max-width: 1400px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media(max-width: $screen-xxs-max) {
    padding-left: 15px;
    padding-right: 15px;
  }
  &:after {
    content: "";
    @extend .triangle;
    position: absolute;
    z-index: 2;
    top: -25px;
    right: 28px;
    margin-left: -6px;
    @media(max-width: $screen-sm-max) {
      right: 64px;
    }
  }
  p {
    margin: 0;
    //padding: 25px 0;
    font-family: $font-bold;
    @media(max-width: $screen-xs-max) {
      font-size: 13px;
      line-height: 18px;
    }
  }
}
