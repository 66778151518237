.dropdown-menu {
  width: 100%;
  margin: 0;
  box-shadow: none;
  > li {
    &:after {
      display: none;
    }
    > a {
      color: $purple;
      padding: 10px 0;
      &:hover {
        color: $purple;
        background: none;
      }
    }
  }

  > .active {
    > a, > a:focus, >a:hover {
      color: $purple;
      background: none;
    }
  }


}
