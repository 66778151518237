video {
  max-width: 100%;
}

.video-description {
  padding-right: 30px;
  @media(min-width: $screen-xs-min) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: $screen-sm-max) {
    padding-right: 0;
    margin-bottom: 60px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 30px;
    }
  }
  p {
    @media(min-width: $screen-xs-min) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.video-col {
  padding-right: 0;
  @media (max-width: $screen-sm-max) {
    padding-left: 0;
    margin-top: 30px;
  }
}

.plyr {
  .plyr__play-large {
    width: 80px;
    height: 80px;
    background-image: url('#{$img_url}/play-button.svg');
    border: none;
    @media (max-width: $screen-sm-max) {
      width: 40px;
      height: 40px;
    }
    svg {
      display: none;
    }
  }
}

.section--video-desc {
  @media (min-width: $screen-lg-min) {
    padding-top: 90px;
  }
  @media (max-width: $screen-xs-max) {
    padding: 30px 0 60px 0;
  }
}

.embedvideo {
  max-width: 100%;
}