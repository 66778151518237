.tag {
  background-color: $blue;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 11px;
  text-transform: uppercase;
  display: inline;
  vertical-align: inherit;
  font-family: $font-bold;
  line-height: 16px;
}
