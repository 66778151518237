.list-filter-wrapper--top {
  margin-bottom: 45px;
}


.list-filter-wrapper {
  text-align: center;
  .jcf-select {
    max-width: 445px;
    margin: 0 auto;
  }
  .btn {
    margin-top: 20px;
  }
}

.filter-dropdown {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 445px;
  margin: 0 auto;
  &.mw--100 {
    max-width: 100%;
  }
}

.filter-dropdown__btn {
  position: relative;
  z-index: 3;
  width: 100%;
  padding-left: 45px;
  padding-right: 45px;
  text-transform: none;
  background-color: $light-gray;
  //background: none;
  font-size: 12px;
  &:before {
    display: inline-block;
    width: 45px;
    height: 40px;
    line-height: 40px;
    text-align: center;

    position: absolute;
    right: 0;
    top: 0;

    cursor: pointer;
    color: $red;

    @extend .iconmoon;
    @extend .icon-arrowdown;
  }
  &[aria-expanded="true"] {
    &:before {
      @extend .icon-close;
    }
  }
}




.bg--white {
  .filter-dropdown__btn {
    background-color: white;
  }
}

.filter-dropdown__items {
  text-align: center;
  top: 20px;
  z-index: 1;
  background: white;
  padding: 30px 15px 10px 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-color: $light-purple3;
  border-top: none;
}

// sign up form

.login-form{
  .filter-dropdown{
    max-width:100%;
    .filter-dropdown__btn{
      background-color:#FFF;
    }
  }
}