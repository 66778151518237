body {
  background: $light-gray;
  font-size: 15px;
  line-height: 20px;
  color: $purple;
  overflow-x: hidden;
  font-family: $font-light;
}

.site-wrapper {
  padding-top: 70px;
  /*@media(max-width: $screen-xs-max) {
    padding-top: 50px;
  }*/
}

.container {
  padding: 0 30px;
  width: 100%;
  max-width: 1650px;
  margin: 0 auto;
  @media(max-width: $screen-xxs-max) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container--checkout {
  max-width: 1050px;
  padding: 0 15px;
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
  @media(max-width: $screen-xxs-max) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.section--main {
  padding: 130px 0 115px 0;
  @media(max-width: $screen-md-max) {
    padding: 80px 0 60px 0;
  }
  @media(max-width: $screen-xs-max) {
    padding: 60px 0 60px 0;
  }
  &.pt--90 {
    @media(min-width: $screen-lg-min) {
      padding-top: 90px;
    }
  }
  &.pt--75 {
    padding-top: 75px;
  }
}

.main--over-hero {
  margin-top: -200px;
  @media(max-width: $screen-xs-max) {
    margin-top: -100px;
  }
}

.main--over-hero-100 {
  margin-top:-100px;
}

.main--over-hero-250 {
  margin-top: -250px;
  @media(max-width: $screen-xs-max) {
    margin-top: -150px;
  }
}

span, .inline {
  display: inline-block;
  vertical-align: middle;
}


.bg--white {
  background-color: white
}

.main--white-to-white {
  padding-top: 20px;
}

.bg--lightgray {
  background-color: $light-gray!important;
}


.h--100 {
  height: 100%;
}

.w--100 {
  width: 100%;
}

.pr--15 {
  padding-right: 15px;
}

.pl--15 {
  padding-left: 15px;
}

.pt--50 {
  padding-top: 50px;
}

img {
  max-width: 100%;
}

.no-padding-top {
  padding-top: 0;
}


.no-margin-bottom {
  margin-bottom: 0!important;
}

hr {
  border-top-color: $gray;
  &.purple {
    border-top-color: $purple;
  }
  &.small {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  &.big {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.mw--1050 {
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
}

.mw--1200 {
  max-width: 1200px;
}

.mt--15 {
  margin-top: 15px !important;
}

.mt--30 {
  margin-top: 30px !important;
}

.mt--60 {
  margin-top: 60px !important;
}

.mt--90 {
  margin-top: 90px !important;
}

.col-aside {

}

.col-main {
  padding-left: 60px;
  border-left: 1px solid $gray;
  @media(max-width: $screen-md-max) {
    padding-left: 30px;
  }
  @media(max-width: $screen-xs-max) {
    padding-left: 20px;
    border-left: none;
  }
  @media(max-width: $screen-xxs-max) {
    padding-left: 15px;
  }
}

.mr--5 {
  margin-right: 5px !important;
}

.mr--10 {
  margin-right: 10px !important;
}

.mr--20 {
  margin-right: 20px !important;
}

.relative {
  position: relative;
}

