.lesson-list__item {
  padding-top: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid $gray;
  &:first-child {
    padding-top: 0;
  }
}

.lesson-list__time, .lesson-list__type {
  font-size: 0;
  i {
    font-size: 14px;
    margin-right: 10px;
    &.icon-video {
      font-size: 12px;
    }
  }
  b {
    font-size: 12px;
    font-family: $font-black;
    text-transform: uppercase;
  }
}


.lesson-list--boxes {
  .lesson-list__item {
    border-bottom: none;
    margin-top: 60px;
    padding-top: 0;
    @media(max-width: $screen-xs-max) {
      margin-top: 30px;
    }
    @media(max-width: $screen-xxs-max) {
      margin-top: 15px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  .box__inner-content {
    @media(min-width: $screen-lg-min) {
      padding-left: 45px;
      padding-right: 45px;
    }
  }
  .lesson-list__table {
    @media(max-width: $screen-xs-max) {
      display: block;
    }
    > .table__cell {
      padding-left: 25px;
      padding-right: 25px;
      @media(max-width: $screen-xs-max) {
        display: block;
        padding: 0;
      }
      &:first-child {
        border-right: 1px solid $gray;
        padding-left: 0;
        @media(max-width: $screen-xs-max) {
          border-right: none;
          margin-bottom: 20px;
        }
      }
      &:last-child {
        padding-right: 0;
      }
    }
    .btn {
      margin-top: 15px;
    }
  }
}

.lesson-list__info {
  span {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid $purple;
    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border: none;
    }
  }
}

.lesson-list--summary {
  .lesson-list__item {
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.lesson-list--small {
  h2 {
    font-size: 14px;
  }
  .box__inner-content {
    padding: 40px 30px;
  }
  .lesson-list__item {
    padding-top: 15px;
    padding-bottom: 15px;
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
