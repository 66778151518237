.triangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}

.bg--lightgray {
  .triangle {
    border-top-color: $light-gray;
  }
  .triangle--up {
    border-top: none;
    border-bottom-color: $light-gray;
  }
}

.triangle--up {
  border-top: none;
  border-bottom: 8px solid white;
}