.contact-info {
  font-family: $font-regular;
  @extend .heading-line;
  &:after {
    display: none;
    margin-bottom: 0!important;
    margin-top: 10px!important;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: $screen-xs-max) {
      display: block;
    }
  }
  @media(max-width: $screen-xs-max) {
    display: block;
    text-align: center;
  }
  .table__row {
    @media(max-width: $screen-xs-max) {
      display: block;
    }
  }
  .table__cell  {
    padding-bottom: 20px;
    @media(max-width: $screen-xs-max) {
      display: inline-block;
    }
    &:first-child {
      width: 25px;
    }
  }
  .icon-phone {
    font-size: 16px;
  }
  .icon-mail {
    font-size: 12px;
  }
  .icon-marker {
    font-size: 20px;
  }
  .icon-pobox {
    font-size: 17px;
    margin-top: -5px;
  }
}

.box--contact {
  h2 {
    margin-bottom: 10px;
  }
  .heading-line {
  }
}

.contact-form {
  .form__inner-content {
    padding-top: 20px;
  }
}