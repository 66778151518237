.infobox {
  padding: 40px;
  margin-top: 30px;
  h3 {
    margin-bottom: 20px;
  }
  small {
    font-size: 14px;
    font-family: $font-lightitalic;
  }
  p {
    margin-bottom: 10px;
  }
}

.infobox--big-padding {
  padding: 50px;
}
