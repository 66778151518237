.checkout-flow {
  counter-reset: checkout-flow-counter;
  list-style: none;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: $screen-md-max) {
    margin-top: 30px;
  }
}

.checkout-flow-item {
  &.is-active {
    .checkout-flow-item__link {
      &:before {
        background-color: $red;
      }
    }
  }

  &.is-disabled {
    .checkout-flow-item__link {
      &:before {
        background: none;
        border: solid 1px $light-purple6;
        color: $light-purple6;
      }
    }
    .checkout-flow-item__text {
      color: $light-purple6;
    }
  }

}

.checkout-flow-item__link {
  position: relative;
  font-family: $font-bold;
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  &:hover {
    text-decoration: none;
  }
  &:before {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    background-color: $purple;
    margin-bottom: 10px;
    color: white;
    counter-increment: checkout-flow-counter;
    content: counter(checkout-flow-counter);
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
  }
}

.checkout-flow-item__text {
  color: $purple;
  position: absolute;
  left: -50px;
  right: -50px;
  bottom: -30px;
  @media (max-width: $screen-md-max) {
    position: static;
    margin-top: 10px;
    padding: 0 5px;
  }
}

.checkout-flow__back {
  position: absolute;
  top: 10px;
  @media (max-width: $screen-md-max) {
    position: static;
    margin-bottom: 30px;
  }
}
