.top-list {
  .table--main {
    @media(max-width: $screen-md-max) {
      display: block;
    }
    > .table__cell {
      @media(max-width: $screen-md-max) {
        display: block;
        padding: 0;
        @media(min-width: $screen-sm-min) {
          margin-bottom: 30px;
        }
      }
    }
  }
  .box {
    @media(max-width: $screen-xs-max) {
      height: auto;
      margin-bottom: 30px;
    }
    @media(max-width: $screen-xxs-max) {
      margin-bottom: 15px;
    }
  }
  .table--border-spacing {
    @media(max-width: $screen-xs-max) {
      display: block;
      padding: 0 30px;
    }
    @media(max-width: $screen-xxs-max) {
      display: block;
      padding: 0 15px;
    }
    > .table__cell {
      @media(max-width: $screen-xs-max) {
        display: block;
      }
    }
  }

}



