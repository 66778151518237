.counter-block {
  counter-reset: block;
}
.counter-block__item {
  //white-space: nowrap;
  //display: table;
  &:before {
    counter-increment: block;
    content: counters(block,"/", decimal-leading-zero) " /";
    //display: inline-block;
    //display: table-cell;
    //white-space: nowrap;
    //vertical-align: top;
    //margin-right: 5px;
    padding-right: 7px;
    color: $blue;
    float: left;
  }
}

h3.counter-block__item {
  &.heading-3--small {
    &:before {
      line-height: 22px;
    }
  }
  &:before {
    font-size: 26px;
    line-height: 22px;
    @media(max-width: $screen-xs-max) {
      font-size: 22px;
    }
  }
}



h2.counter-block__item {
  &.heading-2--big {
    &:before  {
      @media(min-width: $screen-md-min) {
        line-height: 36px;
      }
    }
  }
  &:before {
    font-size: 40px;
    line-height: 30px;
    @media(max-width: $screen-sm-max) {
      line-height: 28px;
      font-size: 35px;
    }
    @media(max-width: $screen-sm-max) {
      line-height: 28px;
    }
    @media(max-width: $screen-xs-max) {
      line-height: 24px;
    }
  }
}

.counter-block__text {
  display: table-cell;
  vertical-align: top;
}

.counter-block--small {
  h3.counter-block__item {
    &:before {
      font-size: 14px;
      line-height: 22px;
      @media(max-width: $screen-xs-max) {
        font-size: 14px;
      }
    }
  }
}

.heading-with-count {
  span {
    display: inline;
  }
}

.count {
  padding-right: 7px;
  color: $blue;
}
