.custom-radio, .custom-checkbox {
  display: inline-block;
  vertical-align: top;
  margin-top: 1px;
  width: 18px;
  height: 18px;
  border: solid 2px $purple;
  position: relative;
  margin-right: 8px;
  &:before {
    position: absolute;
    left: 50%;
    top: 50%;
    /*transform: translateY(-50%);
    margin: 0 auto;*/
    display: none;
  }
}

.custom-radio {
  border-radius: 50%;
  &:before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: $red;
    border-radius: 50%;
    margin-top: -3px;
    margin-left: -3px;
  }
}

input[type="radio"] {
  display: none;
  &:checked {
    +.custom-radio {
      &:before {
        display: block;
      }
    }
  }
}

.radio-wrapper, .checkbox-wrapper {
  @extend .disable-select;
  > label {
    cursor: pointer;
    font-size: 0;
    > i, > span {
      font-size: 15px;
    }
  }
}

.custom-checkbox {
  &:before {
    @extend .iconmoon;
    @extend .icon-check;
    color: $red;
    margin-top: -8px;
    margin-left: -7px;
  }
}

input[type="checkbox"] {
  display: none;
  &:checked {
    +.custom-checkbox {
      &:before {
        display: block;
      }
    }
  }
}



/*.selectboxit-btn.custom-select--gray {
  background-color: $light-gray;
}

.custom-select--gray {
  + .selectboxit-options {
    background-color: $light-gray !important;
  }
}

.country-select {
  .selectboxit-container  .selectboxit-options {
    width: auto;
    left: 16px;
    right: 16px;
    top: 100%!important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}*/


.jcf-select {
  display: block;
  border: none;
  height: 40px;
  background: none;
  text-align: center;
  &.jcf-drop-active {
    .jcf-select-opener {
      &:before {
        @extend .icon-close;
      }
    }
  }
  .jcf-select-text {
    position: relative;
    z-index: 0;
    height: 40px;
    line-height: 40px;
    margin: 0;
    font-family: $font-extrabold;
    font-size: 15px;
    border-radius: 20px;
    border: 1px solid $light-purple3;
    background: $light-gray;
    cursor: pointer;
    > span {
      vertical-align: top;
      line-height: 38px;
    }
  }
  .jcf-select-opener {
    background: none;
    z-index: 0;
    font-size: 12px;
    &:before {
      display: inline-block;
      width: 45px;
      height: 40px;
      line-height: 42px;
      text-align: center;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      color: $red;
      @extend .iconmoon;
      @extend .icon-arrowdown;
    }
  }
  .jcf-select-drop {
    z-index: 1;
    top: 25px;
    left: 0;
    right: 0;
  }
  .jcf-select-drop-content {
    padding: 20px 0 0 0;
    background: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-color: $light-purple3;
    overflow: hidden;
  }
  .jcf-list-content {
    width: 100%!important;
    -webkit-overflow-scrolling: touch;
    //padding-right: 20px;
    ul li {
      padding: 0;
      &:after {
        display: none;
      }
      > span {
        padding: 5px 30px 5px 20px;
      }
    }
  }
  .jcf-list {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .jcf-option {
    color: $purple;
    font-family: $font-regular;
    font-size: 15px;
    cursor: pointer;
  }
  .jcf-select-drop .jcf-hover {
    background: none;
    //opacity: 0.8;
  }
  .jcf-select-drop .jcf-selected {
    background: none;
    //text-decoration: underline;
   /* &:before {
      position: absolute;
      right: 0;
      top: 0;
      @extend .iconmoon;
      @extend .icon-check;

    }*/
  }
  .jcf-scrollbar-dec, .jcf-scrollbar-inc {
    display: none;
  }
  .jcf-scrollbar-vertical {
    width: 4px;
    margin-right: 15px;
    background: none;
    bottom: 15px;
    top: 10px;
    height: auto!important;
    .jcf-scrollbar-slider {
      width: 2px;
      background: $light-gray3;
      margin-left: 0;
      height: 100%!important;
    }
    .jcf-scrollbar-handle {
      width: 4px;
      margin-left: -1px;
      background: purple;
      cursor: pointer;
      border-radius: 10px;
    }
  }

  .jcf-scrollbar-horizontal {
    display: none;
  }
}

.jcf-focus, .jcf-focus * {
  border-color: $light-purple3!important;
}

.bg--white {
  .jcf-select {
    .jcf-select-text, .jcf-select-drop-content, .jcf-list {
      background: white;
    }
  }
}


.bg--lightgray {
  .jcf-select {
    .jcf-select-text {
      background: $light-gray;
    }
  }
}




