.hero {
  position: relative;
  height: 750px;
  background-position: center center;
  //background-size: auto 100%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  @media(max-width: $screen-xs-max) {
    height: 500px;
  }
  .container {
    max-width: 1110px;
  }
  h1 {
    margin-bottom: 0;
  }
  .hero__title {
    font-size: 90px;
    line-height: 75px;
    margin-bottom: 20px;
    //margin: 0 0 75px 0;
    /*@media(max-width: $screen-md-max) {
      margin-bottom: 55px;
    }*/
    @media(max-width: $screen-sm-max) {
      font-size: 65px;
      line-height: 65px;
    }
    @media(max-width: $screen-xs-max) {
      font-size: 50px;
      line-height: 50px;
    }
  }
  h2 {
    margin: 10px 0 5px 0;
  }
  p {
    font-size: 25px;
    line-height: 30px;
    font-family: $font-extralight;
    margin: 0;
    @media(max-width: $screen-sm-max) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .btn {
    margin-top: 55px;
  }
}

.hero--360 {
  height: 360px;
  @media(max-width: $screen-xs-max) {
    height: 250px;
  }
}

.hero--under-main {
  .hero__table {
    padding-bottom: 200px;
    @media(max-width: $screen-xs-max) {
      padding-bottom: 100px;
    }
  }
}

.hero--under-main-100 {
  .hero__table {
    padding-bottom: 100px;
  }
}


.hero__table {
  width: 100%;
  height: 100%;
}

.hero__breadcrumb-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

/*.hero__title {
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}*/
