.related-products-list {
  .table--main {
    margin-bottom: 0;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: margin;
    @media(max-width: $screen-md-max) {
      display: block;
      padding: 0 30px;
    }
    @media(max-width: $screen-xxs-max) {
      padding: 0 15px;
    }
    > .table__cell {
      position: relative;
      @media(max-width: $screen-md-max) {
        display: block;
        padding: 0;
        margin-bottom: 60px;
      }
      @media(max-width: $screen-xs-max) {
        margin-bottom: 30px;
      }
      @media(max-width: $screen-xxs-max) {
        margin-bottom: 15px;
      }
      > .table {
        @media(max-width: $screen-xs-max) {
          display: block;
        }
        > .table__cell {
          @media(max-width: $screen-xs-max) {
            display: block;
          }
        }
      }
    }
  }
  .box__img-content {
    img {
      width: 100%;
    }
    @media(max-width: $screen-xs-max ){
      background: none!important;
      height: auto;
    }
  }
}

.related-list {
  padding-bottom: 115px;
  .section--main {
    padding-bottom: 0;
    @media(min-width: $screen-lg-min) {
      padding-top: 90px;
    }
  }
  .btn--section {
    @media( max-width: $screen-md-max) {
      margin-top: 0;
    }
  }
}



