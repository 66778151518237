.overlay--purple {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $purple;
    opacity: .8;
  }
  > div {
    position: relative;
    z-index: 2;
  }
}