.invoices-wrapper {
  h2 {
    @media(max-width: $screen-xs-max) {
      margin-top: 45px;
    }
  }
}

.invoices {
  font-size: 14px;
  padding-top: 25px;
  margin-bottom: 10px;
  font-family: $font-regular;
  @media(max-width: $screen-xxs-max) {
    display: block;
  }
  > .table__row {
    @media(max-width: $screen-xxs-max) {
      border-bottom: 1px solid $gray;
      display: block;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    &:first-child {
      @media(max-width: $screen-xxs-max) {
        padding-top: 0;
      }
    }
    > .table__row > .table__cell {
      @media(max-width: $screen-xxs-max) {
        display: block;
        padding: 3px 0;
      }
    }
  }
  .table__cell {
    text-align: right;
    padding: 15px 7px;
    border-bottom: 1px solid $gray;
    @media(max-width: $screen-xxs-max) {
      text-align: left;
      border: none;
    }

    &:first-child {
      text-align: left;
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .table__head {
    font-size: 12px;
    @media(max-width: $screen-xxs-max) {
      display: none;
    }
    .table__cell {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .invoices__date {
    text-transform: uppercase;
  }
  .invoices__price {
    font-family: $font-extrabold;
  }
}