// Headings
// -------------------------

h1, h2, h3, h4 {
  font-family: $font-black;
  &.text--bold {
    font-family: $font-bold;
  }
  &.mb--10 {
    margin-bottom: 10px;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

h1 {
  font-size: 45px;
  line-height: 45px;
  margin: 0 0 35px 0;
  @media(max-width: $screen-xs-max) {
    font-size: 40px;
    line-height: 40px;
  }
  @media(max-width: $screen-xxs-max) {
    font-size: 35px;
    line-height: 35px;
  }
}


.heading-1--small {
  font-size: 30px;
  line-height: 30px;
}

.section--main {
  > .container {
    > h1, > h3 {
      margin-bottom: 60px;
    }
  }
}

section {
  > .container {
    > h1, > h2, > h3 {
      text-align: center;
      &:after {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}


h2 {
  font-size: 30px;
  line-height: 30px;
  margin: 0 0 25px 0;
  @media(max-width: $screen-sm-max) {
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  @media(max-width: $screen-xs-max) {
    font-size: 22px;
    line-height: 24px;
  }
}

.heading-2--big {
  font-size: 36px;
  line-height: 36px;
  @media(max-width: $screen-md-max) {
    font-size: 30px;
    line-height: 30px;
  }
  @media(max-width: $screen-md-max) {
    font-size: 25px;
    line-height: 28px;
  }
}

h3 {
  font-size: 22px;
  line-height: 22px;
  margin: 0 0 5px 0;
  &.mb--70 {
    margin-bottom: 70px;
  }
}

.heading-3--big {
  font-size: 25px;
  line-height: 28px;
}

.heading-3--small {
  font-size: 18px;
  line-height: 22px;
}

h4 {
  font-size: 20px;
  line-height: 22px;
  margin: 0 0 15px 0;
}


.heading-line {
  margin-bottom: 0;
  h1 {
    margin-bottom: 10px;
  }
  h2, h3 {
    margin-bottom: 0;
  }
  &:after {
    content: "";
    display: block;
    width: 60px;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 25px;
    background-color: $purple;
  }
  &.heading-line--big {
    &:after {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  &.text--white {
    &:after {
      background-color: white;
    }
  }
  &.text-center {
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.box {
  .heading-line {
    &:after {
      margin-top: 15px;
      margin-bottom: 18px;
    }
    &.heading-line--big {
      &:after {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
    &.heading-2--big {
      &:after {
        margin-top: 20px;
        margin-bottom: 25px;
      }
    }
  }
  .heading-line--big {
    &:after {
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }
}




// Body text
// -------------------------

p {
  font-size: 15px;
  line-height: 20px;
  margin: 0 0 15px 0;
}

b, strong, .text--bold {
  font-family: $font-bold;
  display: inline-block;
  vertical-align: middle;
}



// Contextual colors

.text--white {
  color: white;
}

.text--blue {
  color: $blue;
}

.text--purple {
  color: $purple;
}

.text--red {
  color: $red;
}



// Sizes

.text--10 {
  font-size: 10px;
  line-height: 14px;
}

.text--12 {
  font-size: 12px;
  line-height: 16px;
}

.text--14 {
  font-size: 14px;
  line-height: 18px;
}

.text--20 {
  font-size: 20px;
  line-height: 24px;
}

.text--22 {
  font-size: 22px;
  line-height: 26px;
}

.text--30 {
  font-size: 30px;
  line-height: 34px;
}

.text--50 {
  font-size: 50px;
  line-height: 60px;
}




// Weights

.text--black {
  font-family: $font-black;
}

.text--black-italic {
  font-family: $font-blackitalic;
}

.text--extrabold {
  font-family: $font-extrabold;
}

.text--regular {
  font-family: $font-regular;
}

.text--light-italic {
  font-family: $font-lightitalic;
}

.text--light {
  font-family: $font-light;
}



// Lists
// -------------------------

ul,
ol {
  margin: 0 0 15px 0;
  padding-left: 11px;
  ul,
  ol {
    margin-bottom: 0;
  }
}

ul {
  list-style: none;
  font-size: 14px;
  line-height: 20px;
  li {
    span, a {
      display: inline-block;
      vertical-align: top;
    }
    a {
      color: $purple;
    }
    > ul {
      padding-left: 20px;
    }

  }
  &.list-unstyled {
    > li {
      padding-left: 0;
    }
  }
}

ul {
  padding-left: 0;
  > li {
    position: relative;
    padding-left: 20px;
    &:after {
      content: "\2022";
      position: absolute;
      left: 0;
      top: 0;
      font-size: 18px;
      display: inline-block ;
      vertical-align: top;
      line-height: 20px;
    }
  }
  /*&.text--black {
    > li {
      &:after {
        font-size: 12px;
      }
    }
  }*/
}

// Text align
// -------------------------
.text--left {
  text-align: left !important;
}
.text--right {
  text-align: right !important;
}


.cursor-pointer {
  cursor: pointer;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/*.word-wrap {
  @include word-wrap();
}*/
