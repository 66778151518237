.breadcrumb-wrapper {
  text-align: center;
}

.breadcrumb {
  font-size: 0;
  display: inline-block;
  font-family: $font-light;
  padding: 8px 5px;
  border-bottom: 1px solid $light-purple3;
  border-radius: 0;
  margin-bottom: 35px;
  margin-top: 15px;
  background: none;
  a, span {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    line-height: 16px;
  }
  .breadcrumb__item {
    font-size: 0;
    line-height: 0;
    display: inline-block;
    vertical-align: middle;
    padding-left: 0;
    &:after {
      display: none;
    }
    a {
      color: $purple;
    }
    &+li {
      &:before {
        font-size: 6px;
        @extend .inline;
        @extend .iconmoon;
        @extend .icon-arrowright;
        color: $purple!important;
        padding: 0 7px!important;
      }
    }
  }
}



.breadcrumb__item--active {
  font-family: $font-black;
}

.hero {
  .breadcrumb {
    border-bottom: 1px solid rgba(255,255,255,.2);
    margin-top: 5px;
    a, span {
      color: white;
    }
  }
  .breadcrumb__item {
    &+li {
      &:before {
       color: white!important;
      }
    }
  }
}
