/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 17, 2017 */

@font-face {
  font-family: 'nunito_sansblack';
  src: url('#{$font_url}nunitosans-black-webfont.eot');
  src: url('#{$font_url}nunitosans-black-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-black-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-black-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-black-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-black-webfont.svg#nunito_sansblack') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sansblack_italic';
  src: url('#{$font_url}nunitosans-blackitalic-webfont.eot');
  src: url('#{$font_url}nunitosans-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-blackitalic-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-blackitalic-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-blackitalic-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-blackitalic-webfont.svg#nunito_sansblack_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sansbold';
  src: url('#{$font_url}nunitosans-bold-webfont.eot');
  src: url('#{$font_url}nunitosans-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-bold-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-bold-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-bold-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-bold-webfont.svg#nunito_sansbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sansbold_italic';
  src: url('#{$font_url}nunitosans-bolditalic-webfont.eot');
  src: url('#{$font_url}nunitosans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-bolditalic-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-bolditalic-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-bolditalic-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-bolditalic-webfont.svg#nunito_sansbold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sansextrabold';
  src: url('#{$font_url}nunitosans-extrabold-webfont.eot');
  src: url('#{$font_url}nunitosans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-extrabold-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-extrabold-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-extrabold-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-extrabold-webfont.svg#nunito_sansextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sansextrabold_italic';
  src: url('#{$font_url}nunitosans-extrabolditalic-webfont.eot');
  src: url('#{$font_url}nunitosans-extrabolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-extrabolditalic-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-extrabolditalic-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-extrabolditalic-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-extrabolditalic-webfont.svg#nunito_sansextrabold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sansextralight';
  src: url('#{$font_url}nunitosans-extralight-webfont.eot');
  src: url('#{$font_url}nunitosans-extralight-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-extralight-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-extralight-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-extralight-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-extralight-webfont.svg#nunito_sansextralight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sansextralight_italic';
  src: url('#{$font_url}nunitosans-extralightitalic-webfont.eot');
  src: url('#{$font_url}nunitosans-extralightitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-extralightitalic-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-extralightitalic-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-extralightitalic-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-extralightitalic-webfont.svg#nunito_sansextralight_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sansitalic';
  src: url('#{$font_url}nunitosans-italic-webfont.eot');
  src: url('#{$font_url}nunitosans-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-italic-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-italic-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-italic-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-italic-webfont.svg#nunito_sansitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sanslight';
  src: url('#{$font_url}nunitosans-light-webfont.eot');
  src: url('#{$font_url}nunitosans-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-light-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-light-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-light-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-light-webfont.svg#nunito_sanslight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sanslight_italic';
  src: url('#{$font_url}nunitosans-lightitalic-webfont.eot');
  src: url('#{$font_url}nunitosans-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-lightitalic-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-lightitalic-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-lightitalic-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-lightitalic-webfont.svg#nunito_sanslight_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sansregular';
  src: url('#{$font_url}nunitosans-regular-webfont.eot');
  src: url('#{$font_url}nunitosans-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-regular-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-regular-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-regular-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-regular-webfont.svg#nunito_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sanssemibold';
  src: url('#{$font_url}nunitosans-semibold-webfont.eot');
  src: url('#{$font_url}nunitosans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-semibold-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-semibold-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-semibold-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-semibold-webfont.svg#nunito_sanssemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito_sanssemibold_italic';
  src: url('#{$font_url}nunitosans-semibolditalic-webfont.eot');
  src: url('#{$font_url}nunitosans-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font_url}nunitosans-semibolditalic-webfont.woff2') format('woff2'),
  url('#{$font_url}nunitosans-semibolditalic-webfont.woff') format('woff'),
  url('#{$font_url}nunitosans-semibolditalic-webfont.ttf') format('truetype'),
  url('#{$font_url}nunitosans-semibolditalic-webfont.svg#nunito_sanssemibold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}