.link {
  display: inline-block;
  vertical-align: middle;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.link--purple {
  color: $purple;
  &:hover, &:focus {
    color: $purple;
  }
}

.link--white {
  color: white;
  &:hover, &:focus {
    color: white;
  }

}

.link--toggle {
  position: relative;
  span {
    font-family: $font-extrabold;
    font-size: 14px;
  }
  i {
    color: $red;
    font-size: 12px;
  }
  &[aria-expanded="true"] {
    i {
      &:before {
        @extend .icon-close;
      }
    }
  }
}

.link--toggle-close {
  i {
    color: $purple;
  }
}

a[href^="mailto:"] {
  @include word-wrap;
}