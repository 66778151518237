.address-list {
  margin-top: 50px;
  .address-list__item {
    display: table;
    @media(max-width: $screen-xxs-max) {
      display: block;
    }
    .address-list__name, .address-list-remove-btn {
      display: table-cell;
      vertical-align: top;
      @media(max-width: $screen-xxs-max) {
        display: block;
      }
    }
    .address-list__name {
      width: 100%;
      padding-right: 30px;
      @media(max-width: $screen-xxs-max) {
        padding-right: 0;
      }
    }
    .address-list__remove-btn {
      white-space: nowrap;
      @media(max-width: $screen-xxs-max) {
        margin-top: 25px;
      }
    }
    .address-list--delete {
      @extend .link--purple;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    .icon-delete {
      @extend .icon--right;
    }
  }
}