/*
 * jquery.selectBoxIt.css 3.8.1
 * Author: @gregfranko
 */

/*
  Common CSS Properties
  ---------------------
  These properties will be applied to any themes that you use
*/

/* SelectBoxIt container */
.selectboxit-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

/* Styles that apply to all SelectBoxIt elements */
.selectboxit-container * {
  /* Prevents text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  white-space: nowrap;
}

/* Button */
.selectboxit-container .selectboxit {
  //width: 220px; /* Width of the dropdown button */
  width:100%!important;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 3;
}

/* Height and Vertical Alignment of Text */
.selectboxit-container span, .selectboxit-container .selectboxit-options a {
  height: 40px; /* Height of the drop down */
  line-height: 40px; /* Vertically positions the drop down text */
  display: block;
}

/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
  outline: 0;
}

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled, .selectboxit-options .selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}

/* Button Text */
.selectboxit-text {
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: $font-extrabold;
  font-size: 14px;
  padding: 0 15px;
  width: 100%;
  max-width: 100%!important;
}

/* Options List */
.selectboxit-container .selectboxit-options {
  //display: block!important;

  width: 100%;
  //max-height: none!important;
  max-height: 300px;
  margin: 0;
  padding: 0;
  background: white;

  border-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  text-align: center;
  list-style: none;

  position: absolute;
  top:25px !important;
  font-family: $font-extrabold;
  z-index: 1;

  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;

  border: 1px solid $light-purple3;

  padding-top: 20px;
  padding-bottom: 5px;

  .selectboxit-focus {
    color: $purple;
    background-color: $light-gray3;
    a {
      color: $purple;
    }
  }
}

/* Individual options */
.selectboxit-option .selectboxit-option-anchor{
  padding: 0 15px;
}

/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
  text-decoration: none;
}

/* Individual Option Optgroup Header */
.selectboxit-option, .selectboxit-optgroup-header {
  margin: 0;
  padding: 0;
  &:after {
    display: none;
  }
}


.selectboxit-arrow-container {
  font-size: 0;
  width: 32px;
  height: 38px!important;
  line-height: 38px!important;
  padding-right: 15px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: left;
}

/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  color: $purple;
  font-size: 12px;
}

.selectboxit-option-icon-container {
  display: none!important;
}

.selectboxit-container .selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}


.selectboxit-rendering {
  display: inline-block !important;
  *display: inline !important;
  zoom: 1 !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

/*
  Default Theme
  -------------
  Note: Feel free to remove all of the CSS underneath this line if you are not using the default theme
*/
.selectboxit-btn {
  background-color: white;
  border: 1px solid $light-purple3;
}

.selectboxit-default-arrow {
  width: 0;
  height: 0;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.selectboxit-list {
  background-color: #ffffff;
}

.form-group .selectboxit-container {
  width:100%;
  .selectboxit-arrow{
    color:$red;
  }
}
