.aside-nav__item {
  &:after {
    display: none;
  }
  a {
    position: relative;
    font-size: 15px;
    color: $purple;
    text-transform: uppercase;
    font-family: $font-regular;
    display: block;
    padding: 7px 0;
    padding-left: 20px;
    &:before {
      position: absolute;
      left: 0;
      margin-top: 3px;
      @extend .iconmoon;
      @extend .icon-arrowright;
      font-size: 11px;
      color: $red;
      display: none;
    }
    &:hover, &:focus {
      color: $purple;
      text-decoration: none;
    }
    &:hover {
      &:before {
        display: inline-block;
      }
    }
  }
  &:first-child {
    a {
      padding-top: 0;
    }
  }
  &.active {
    a {
      font-family: $font-extrabold;
      &:before {
        display: inline-block;
      }
    }
  }
}

