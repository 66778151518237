.icon-cart {
  position: relative;
  //color: $purple;
  /*&:hover, &:focus {
    color: $purple;
    text-decoration: none;
  }*/
}


.shopping-cart__count {
  width: 16px;
  height: 16px;
  background-color: $red;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 18px;
  font-size: 9px;
  font-family: $font-bold;

  position: absolute;
  bottom: -5px;
  right: -5px;
}