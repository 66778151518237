button, .btn {
  //font-size: 0;
  color: white;
  font-family: $font-extrabold;
  border-radius: 20px;
  text-transform: uppercase;
  border: none;
  &:hover, &:focus {
    color: white;
    outline: none;
    border: none;
  }

  &:active, &.active {
    box-shadow: none;
  }
  &:focus, &:active:focus, &:active.focus, &.active:focus, &.active:focus {
    outline: none
  }
  span {
    font-size: 14px;
    line-height: 20px;
    @media(max-width: $screen-sm-max) {
      font-size: 13px;
    }
    @media(max-width: $screen-xs-max) {
      font-size: 12px;
    }
    @media(max-width: $screen-xxs-max) {
      font-size: 11px;
    }
  }
  .icon-cart {
    margin-top: -5px;
  }
  .icon-play2 {
    font-size: 11px;
  }
  .icon-eye {
    font-size: 10px;
  }
  [class^="icon-"], [class*=" icon-"] {
    margin-right: -5px;
  } 
}



// btn size
//

.btn--small {
  padding: 5px 20px;
  span {
    font-size: 11px;
  }
}

.btn--big {
  padding: 9px 25px;
}

.btn--login {
  font-size: 14px;
  padding: 4px 25px;
}



// btn background color
//
.btn-default {
  background: none;
  &:hover {
    background: none;
  }
}

.btn--purple {
  background-color: $purple;
}

.btn--red {
  background-color: $red;
}

.btn--blue {
  background-color: $blue;
}

.btn--transparent {
  background: none;
  border:  1px solid $purple;
  color: $purple;
  &:hover, &:focus {
    color: $purple;
    border:  1px solid $purple;
  }
}

.btn--transparent-lightpurple {
  background: none;
  border:  1px solid $light-purple3;
  color: $purple;
  &:hover, &:focus {
    color: $purple;
    border:  1px solid $light-purple3;
  }
}

// btn status
//

.btn--inactive {
  pointer-events: none;
  cursor: default;
  background-color: $light-purple4;
  color: white;
}



// btn text
//
.btn--no-text-transform {
  font-size: 0;
  text-transform: none;
}

.btn--text-regular {
  font-family: $font-regular;
}



// btn width
//
.btn--340 {
  width: 100%;
  max-width: 340px;
  &.btn--big {
    padding: 9px;
  }
}

.btn--270 {
  width: 100%;
  max-width: 270px;
  &.btn--big {
    padding: 9px;
  }
}

.btn--section {
  margin-top: 55px;
  @media(max-width: $screen-md-max) {
    margin-top: 35px;
  }
}


// btn hamburger
//
.btn-hamburger {
  width: 40px;
  font-size: 0;
  line-height: 0;
  border: none;
  background: none;
  padding: 15px 0;
  &.opened {
    .icon-bar {
      width: 28px;
      background: black;
      transition: .5s ease;
      &:nth-child(1){
        transform: rotate(-45deg)translateY(5px)translateX(-5px);
      }
      &:nth-child(2){
        opacity:0;
      }
      &:nth-child(3){
        transform:rotate(45deg)translateY(-6px)translateX(-6px);
      }
    }
  }
  .icon-bar {
    width: 28px;
    height: 3px;
    background: black;
    transition: .5s ease;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(1){
      transform: rotate(0)translateY(0)translateX(0);
    }
    &:nth-child(2){
      opacity:1;
      transform: rotate(0)translateY(0)translateX(0);
    }
    &:nth-child(3){
      transform:rotate(0)translateY(0)translateX(0);
      margin-bottom: 0;
    }
  }
}