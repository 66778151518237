.mCSB_scrollTools {
  width: 30px;
  margin: 10px 0;
  .mCSB_draggerRail {
    background-color: $gray;
  }
  .mCSB_dragger {
    .mCSB_dragger_bar, &:hover .mCSB_dragger_bar, &.mCSB_dragger_onDrag .mCSB_dragger_bar {
      background-color: $purple;
    }
  }
}
