.myaccount-form {
  .checkbox-wrapper {
    margin: 15px 0 10px 0;
  }
}

.col-aside {
  @media(max-width: $screen-xs-max) {
    padding-bottom: 45px;
  }
}

